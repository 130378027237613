import en from "./en";
import fr from "./fr";
import de from "./de";
import es from "./es";
import jp from "./jp";
import zh from "./zh";
import en_camper from "./en_camper";
import en_alaska from "./en_alaska";
import en_jms from "./en_jms.json";
import en_merc from "./en_merc.json";
import da from "./da.json";
import en_tas from "./en_tas.json";
import it from "./it";
import nl from "./nl.json";
import ru from "./ru.json";

export default {
  en_camper,
  en_alaska,
  en,
  fr,
  de,
  es,
  jp,
  zh,
  en_jms,
  da,
  en_tas,
  it,
  nl,
  ru,
  en_merc
};
