import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Loading from "./components/Static/Loading/Loading";
import PrivateRoute from "./tools/PrivateRoute";

import { connect } from "react-redux";
import { refreshToken, decodeUrlData } from "./redux/actions/AuthActions";
import { fetchClient } from "./redux/actions/ApiActions";
import { setDefaultCheckTimes } from "./redux/actions/formActions";
import i18n from "i18next";
import ReactPixel from "react-facebook-pixel";
import store from "./redux/createStore";

(async function () {
  await Promise.all([
    import("./App.css"),
    import("rsuite/dist/styles/rsuite-default.css"),
  ]);
})();

const home = React.lazy(() => import("./containers/home/Home"));
const Flow = React.lazy(() => import("./containers/Flow/Flow"));
const bookingSuccess = React.lazy(() =>
  import("./containers/RegisterSuccess/RegisterSuccess")
);
const Documentation = React.lazy(() => import("./Documentation/Main"));
const AddOns = React.lazy(() => import("./containers/Flow/AddOns"));
const Insurance = React.lazy(() => import("./containers/Flow/Insurance"));
const DriverInfo = React.lazy(() => import("./containers/Flow/DriverInfo"));
const Payment = React.lazy(() => import("./containers/Flow/Payment"));
const Extras = React.lazy(() => import("./containers/Flow/Extras"));
const Terms = React.lazy(() => import("./containers/Flow/Terms"));
const Info = React.lazy(() => import("./containers/Flow/Info"));
const Checkout = React.lazy(() => import("./containers/Flow/Checkout"));
const VehicleView = React.lazy(() => import("./containers/Flow/VehicleView"));
const SelectVehicle = React.lazy(() =>
  import("./containers/Flow/SelectVehicle")
);

const options = {
  autoConfig: false, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

class App extends React.Component {
  urlParams = new URLSearchParams(window.location.search);

  componentDidMount() {
    const lang = this.urlParams.get("lang");

    if (lang) {
      i18n.changeLanguage(lang);
    }

    const clientId = this.urlParams.get("clientid");

    if (clientId === "1364") {
      ReactPixel.init("927928803968841", null, options);
      ReactPixel.pageView();
    }

    this.props.$refreshToken(clientId);
  }

  componentDidUpdate(prevProps) {
    let { form } = store.getState();
    const clientId = this.urlParams.get("clientid");
    const myParam = this.urlParams.get("q");

    if (prevProps.token !== this.props.token) {
      this.props.$fetchClient(clientId).then((_) => {
        
        let features = JSON.parse(
          localStorage.getItem("reservation_plugin_client_features")
        );

        if (
          this.urlParams.get("pickup_location") ||
          this.urlParams.get("dropoff_location")
        ) {
          const pickupLocationID = features.locations.find(
            (loc) =>
              loc.locationName.replace("&", "").toLowerCase() ===
              this.urlParams.get("pickup_location").toLowerCase()
          );
          const dropOffLocationID = features.locations.find(
            (loc) =>
              loc.locationName.replace("&", "").toLowerCase() ===
              this.urlParams.get("dropoff_location").toLowerCase()
          );

          const pickupTime = new Date(this.urlParams.get("pickup_date"));
          const p_userTime = this.urlParams.get("pickup_time");

          const [p_hours, p_minutes] = p_userTime.split(":");

          pickupTime.setHours(parseInt(p_hours));
          pickupTime.setMinutes(parseInt(p_minutes));

          const dropOffTime = new Date(this.urlParams.get("dropoff_date"));
          const d_userTime = this.urlParams.get("dropoff_time");

          const [d_hours, d_minutes] = d_userTime.split(":");

          dropOffTime.setHours(parseInt(d_hours));
          dropOffTime.setMinutes(parseInt(d_minutes));

          let data = {
            age: "25",
            differentDropOffLocation:
              pickupLocationID !== dropOffLocationID
                ? dropOffLocationID.locationId
                : null,
            differentDropOff: pickupLocationID !== dropOffLocationID,
            pickUpDate: new Date(this.urlParams.get("pickup_date")),
            pickUpTime: pickupTime,
            returnDate: new Date(this.urlParams.get("dropoff_date")),
            returnTime: dropOffTime,
            location: pickupLocationID.locationId,
            totMonths: "",
            makeID: form.makeID,
            enableEditButton: form.enableEditButton,
            secondRedirection: form.secondRedirection,
            VehicleId: form.VehicleId,
            selectedVehicle: form.selectedVehicle,
            vehicleTypeId: form.vehicleTypeId,
            makeName: form.makeName,
            subDropOffLocation: form.subDropOffLocation,
            subPickUpLocation: form.subPickUpLocation,
            subDropOffLocationName: form.subDropOffLocationName,
            subPickUpLocationName: form.subPickUpLocationName,
            dropOffHotelName: form.dropOffHotelName,
            pickUpHotelName: form.pickUpHotelName,
            sortDirection: form.sortDirection,
            promoId: form.promoId,
          };

          if (!!myParam) {
            data = { ...data, ...JSON.parse(Buffer.from(myParam, "base64")) };
          }

          data.differentDropOffLocation = JSON.parse(Buffer.from(myParam, "base64")).differentDropOffLocation || data.location;

          let vehicleType = this.urlParams.get("vehicle_type");

          if (vehicleType) {
            features.vehicleTypes.forEach(({ id, value }) => {
              if (value.toLowerCase() === vehicleType.toLowerCase()) {
                data.vehicleTypeId = id;
              }
            });
          }

          this.props.$decodeUrlData(data, clientId);
        } else if (this.urlParams.get("vehicle_type")) {
          let vehicleTypeId = "";

          features.vehicleTypes.forEach(({ id, value }) => {
            if (
              value.toLowerCase() ===
              this.urlParams.get("vehicle_type").toLowerCase()
            ) {
              vehicleTypeId = id;
            }
          });

          this.props.$decodeUrlData({ vehicleTypeId }, clientId);

          this.props.$setDefaultCheckTimes();
        } else {
          this.props.$setDefaultCheckTimes();
        }
      });
    }
  }

  render() {
    return (
      <React.Suspense fallback={<Loading />}>
        <Router>
          <Switch>
            <PrivateRoute
              path="/"
              exact
              auth={!!this.urlParams.get("clientid")}
              component={home}
            />
            <PrivateRoute
              path="/flow/SelectVehicle"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />
            <PrivateRoute
              path="/booking/success"
              auth={true}
              exact
              to="/"
              component={bookingSuccess}
            />
            <PrivateRoute
              path="/flow/AddOns"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />
            <PrivateRoute
              path="/flow/Insurance"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />
            <PrivateRoute
              path="/flow/DriverInfo"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />
            <PrivateRoute
              path="/flow/Payment"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />
            <PrivateRoute
              path="/flow/SelectCar"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />
            <PrivateRoute
              path="/flow/Extras"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />
            <PrivateRoute
              path="/flow/Terms"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />
            <PrivateRoute
              path="/flow/Info"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />
            <PrivateRoute
              path="/flow/Checkout"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />
            <PrivateRoute
              path="/flow/VehicleView"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />

            <PrivateRoute
              path="/flow/SelectMultiVehicle"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />

            <PrivateRoute
              path="/flow/SelectCarWithDetails"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />

            <PrivateRoute
              path="/flow/SelectRv"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />

            <PrivateRoute
              path="/flow/SelectBoat"
              exact
              auth={!!this.urlParams.get("clientid")}
              to="/"
              component={Flow}
            />

            <Route path="/docs" component={Documentation} />
          </Switch>
        </Router>
      </React.Suspense>
    );
  }
}

const mapPropsToState = (store) => {
  return {
    reservationNumber: store.form.reservationNumber,
    token: store.auth.token,
  };
};

const mapPropsByDispatch = (dispatch) => {
  return {
    $refreshToken: (clientId) => dispatch(refreshToken(clientId)),
    $decodeUrlData: (crypt, clientId) =>
      dispatch(decodeUrlData(crypt, clientId)),
    $fetchClient: (clientId) => dispatch(fetchClient(clientId)),
    $setDefaultCheckTimes: () => dispatch(setDefaultCheckTimes()),
  };
};

export default connect(mapPropsToState, mapPropsByDispatch)(App);
