import ActionsType from "./ActionsType";
import store from "../createStore";

const importHomeComponent = (componentPath) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      componentPath = componentPath || state.UISettings.homeView;

      const path = componentPath.split("/");

      const component = await import(
        `../../components/Home/${path[0]}/${path[1]}/${path[1]}`
      );

      dispatch({
        type: ActionsType.IMPORT_HOME_COMPONENT,
        data: { component: component.default },
      });
    } catch (err) {
      console.log(err);
      const component = await import(
        "../../components/Static/pageNotFound/pageNotFound"
      );
      dispatch({
        type: ActionsType.IMPORT_HOME_COMPONENT,
        data: { component: component.default },
      });
    }
  };
};

const importFlow = (componentPath) => {
  return async (dispatch) => {
    try {
      const path = componentPath.split("/");

      const component = await import(
        `../../components/Flow/${path[0]}/${path[1]}/${path[1]}`
      );
      dispatch({
        type: ActionsType.IMPORT_FLOW_COMPONENT,
        data: { component: component.default, componentName: componentPath },
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const importStyles = (styleName) => {
  return async (dispatch) => {
    try {
      const component = await import(
        `../../DynamicStyles/${styleName}.module.css`
      );
      dispatch({
        type: ActionsType.IMPORT_STYLES,
        data: { component: component.default },
      });
    } catch (err) {
      console.log(err);
      const component = await import(`../../DynamicStyles/default.module.css`);
      dispatch({
        type: ActionsType.IMPORT_STYLES,
        data: { component: component.default },
      });
    }
  };
};

const importBookingSuccess = (componentPath) => {
  return async (dispatch) => {
    try {
      componentPath =
        componentPath || store.getState().UISettings.bookingSuccessView;

      const path = componentPath.split("/");

      const component = await import(
        `../../components/Flow/${path[0]}/${path[1]}/${path[1]}`
      );

      dispatch({
        type: ActionsType.IMPORT_BOOKING_SUCCESS_COMPONENT,
        data: { component: component.default },
      });
    } catch (err) {
      console.log(err);
      alert("Error Occured loading the page");
    }
  };
};

const importNavbar = (componentPath) => async (dispatch) => {
  try {
    const state = store.getState();

    componentPath = componentPath || state.UISettings.navBar;
    let component;

    const path = componentPath.split("/");

    component = await import(
      `../../components/NavBars/${path[0]}/${path[1]}/${path[1]}`
    );

    dispatch({
      type: ActionsType.IMPORT_NAVBAR,
      data: { component: component.default },
    });
  } catch (err) {
    console.log(err);
  }
};

const importFooter = (componentName) => async (dispatch) => {
  try {
    const state = store.getState();

    componentName = componentName || state.UISettings.footer;

    let component;

    component = await import(
      `../../components/Footers/${componentName}/Footer.js`
    );

    dispatch({
      type: ActionsType.IMPORT_FOOTER,
      data: { component: component.default },
    });
  } catch (err) {
    console.log(err);
  }
};

const importSummary = (componentName) => async (dispatch) => {
  try {
    const state = store.getState();

    componentName = componentName || state.UISettings.summaryView;

    let component;

    component = await import(`../../components/Summary/${componentName}.js`);

    dispatch({
      type: ActionsType.IMPORT_SUMMARY,
      data: { component: component.default },
    });
  } catch (err) {
    console.log(err);
  }
};

export {
  importHomeComponent,
  importBookingSuccess,
  importFlow,
  importStyles,
  importNavbar,
  importFooter,
  importSummary,
};
