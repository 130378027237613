import React from 'react';
import styles from './Loading.module.css';

class Loading extends React.PureComponent {

    render() {
        return (
            <div className={styles.PageLoading}>
                <div className={styles.threeBalls}>
                    <div className={styles.ball + ' '+ styles.ball1}></div>
                    <div className={styles.ball + ' '+ styles.ball2}></div>
                    <div className={styles.ball + ' '+ styles.ball3}></div>
                </div>
            </div>
        )
    }
}

export default Loading;
