import React from 'react';
import privateImg from '../../../assets/images/private.svg';

class PermissionDenied extends React.PureComponent {

    render() {
        return (
            <div style={{ padding: '20px', marginTop: '60px', textAlign: 'center' }}>
                <h4 style={{ padding: '20px' }}>Permission Denied</h4>
                <img src={privateImg} alt='404 ... page not found' style={{ width: '50vw', margin: 'auto' }} />
            </div>
        )
    }
}

export default PermissionDenied;
