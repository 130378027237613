import ActionType from "./ActionsType";
import { mapStoreHoursAsDefault } from "../../tools/utils";
import { changeUISettings } from "./UIActions";
import {
  fetchMiscCharges,
  fetchInventoryCharges,
  fetchTaxes,
  fetchStoreHours,
  fetchReturnLocationsStoreHours,
} from "./ApiActions";
import store from "../createStore";

const setDefaultCheckTimes = () => {
  return (dispatch) => {
    const defaultHours = mapStoreHoursAsDefault();
    dispatch(changeForm("pickUpDate", defaultHours.defaultCheckoutDate));
    dispatch(changeForm("returnDate", defaultHours.defaultCheckinDate));
    dispatch(changeForm("pickUpTime", defaultHours.defaultCheckoutTime));
    dispatch(changeForm("returnTime", defaultHours.defaultCheckinTime));
  };
};

const changeForm = (key, value) => {
  if (["pickUpDate", "pickUpTime", "returnDate", "returnTime"].includes(key)) {
    value = new Date(value);
  }
  if (
    [
      "location",
      "differentDropOffLocation",
      "subDropOffLocation",
      "subPickUpLocation",
    ].includes(key)
  ) {
    value = parseInt(value);
  }

  const state = store.getState();

  if (key === "promoId") {
    // window.localStorage.setItem("promoId", value);
    state.form.promoId = [window.localStorage.setItem("promoId", value)];
  }

  return (dispatch) => {
    if (key === "selectedVehicle") {
      dispatch({ type: ActionType.CLEAR_VEHICLE_ITEMS });
      dispatch(fetchMiscCharges(value.VehicleTypeId));
      dispatch(fetchInventoryCharges(value.VehicleTypeId));
    } else if (key === "location") {
      dispatch(fetchStoreHours(value));
    } else if (key === "differentDropOff" && state.form.location) {
      dispatch(fetchReturnLocationsStoreHours(state.form.location));
    } else if (key === "differentDropOffLocation") {
      dispatch(fetchReturnLocationsStoreHours(value));
    }

    dispatch({ type: ActionType.CHANGE_FORM, data: { key, value } });
  };
};

const changeAdditionalDriver = (key, value, i) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHANGE_ADDITIONAL_DRIVER,
      data: { key, value, i },
    });
  };
};

const setPrice = (price = 0, position) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.SET_PRICE,
      data: { price: parseFloat(price), position },
    });
  };
};

const clearPrice = () => {
  return (dispatch) => {
    dispatch({ type: ActionType.CLEAR_PRICE, data: {} });
  };
};

const addPrice = (price) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.ADD_PRICE,
      data: { price: parseFloat(price) },
    });
  };
};

const updateMisc = (misc, quantity, startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.UPDATE_MISC,
      data: { misc, quantity, startDate, endDate },
    });
    dispatch(addPrice(misc.Total));
    dispatch(fetchTaxes());
  };
};

const updateInventory = (inv, quantity, startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.UPDATE_INVENTORY,
      data: { inv, quantity, startDate, endDate },
    });
    dispatch(addPrice(inv.Total));
    dispatch(fetchTaxes());
  };
};

const addMisc = (misc) => {
  return (dispatch) => {
    if (misc.Name === "AdditionalDriver") {
      dispatch(changeUISettings("AdditionalDriver", true));
    }
    if (misc.IsQuantity && !misc.Quantity) {
      misc.Quantity = 1;
    }
    dispatch({ type: ActionType.ADD_MISC, data: { misc } });
    dispatch(addPrice(misc.Total));
    dispatch(fetchTaxes());
  };
};

const addInventory = (inventory) => {
  return (dispatch) => {
    if (inventory.IsQuantity && !inventory.Quantity) {
      inventory.Quantity = 1;
    }

    dispatch({ type: ActionType.ADD_INVENTORY, data: { inventory } });
    dispatch(addPrice(inventory.Total));
    dispatch(fetchTaxes());
  };
};

const removeMisc = (misc) => {
  return (dispatch) => {
    if (!misc.IsOptional && misc.Options.length == 0) {
      return;
    }
    if (misc.Name === "AdditionalDriver") {
      dispatch(changeUISettings("AdditionalDriver", false));
    }

    dispatch({ type: ActionType.REMOVE_MISC, data: { miscId: misc.Id } });
    dispatch(addPrice(-1 * misc.Total));
    dispatch(fetchTaxes());
  };
};

const removeInventory = (inventory) => {
  return (dispatch) => {
    if (!inventory.IsOptional) {
      return;
    }

    dispatch({
      type: ActionType.REMOVE_INVENTORY,
      data: { inventoryId: inventory.Id },
    });
    dispatch(addPrice(-1 * inventory.Total));
    dispatch(fetchTaxes());
  };
};

const clearvehicleTypes = () => {
  return (dispatch) => {
    dispatch({ type: ActionType.CLEAR_VEHICLE_TYPES, data: {} });
  };
};

const addImage = (image) => {
  return (dispatch) => {
    dispatch({ type: ActionType.ADD_IMAGE, data: { image } });
  };
};

export {
  changeForm,
  changeAdditionalDriver,
  setPrice,
  addPrice,
  addMisc,
  addInventory,
  updateMisc,
  updateInventory,
  removeMisc,
  removeInventory,
  setDefaultCheckTimes,
  clearPrice,
  clearvehicleTypes,
  addImage,
};
