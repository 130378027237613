import ActionType from './ActionsType';
import { importFlow } from './importActions';
import store from '../createStore';
import { Alert } from 'rsuite';
import { setPrice } from './formActions';

const changeStepper = position => {

    return async dispatch => {
        try {
            const state = store.getState();

            const priceArr = state.price || [];

            priceArr.forEach((price, index) => {
                if (index >= position) {
                    dispatch(setPrice(0, index));
                }
            });


            if (!!!state.imports.flow[position]) {
                const componentName = state.UISettings.flow.split('-')[position];
                if (!!!componentName) return;

                dispatch(importFlow(componentName));
            }

            dispatch(changeUISettings('currentActiveFlow', position));


        } catch (err) {
            console.log(err);
        }
    }

}

const toggleModal = (key, value) => dispatch => {
    dispatch({ type: ActionType.TOGGLE_MODAL, data: { key, value } });
}


const alert = (type = 'info', message = 'success', duration = 3000) => dispatch => {
    Alert[type](message, duration);
}


const changeUISettings = (key, value) => dispatch => {
    dispatch({ type: ActionType.CHANGE_UISETTINGS, data: { key, value } });
}


const setDocsState = () => dispatch => {
    dispatch({ type: ActionType.SET_DOCS, data: {} })
}



export { changeStepper, alert, toggleModal, changeUISettings, setDocsState };
