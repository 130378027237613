import ActionType from "../actions/ActionsType";
import Loading from "../../components/Static/Loading/Loading";
import CompactNavbar from "../../components/NavigationDetails/NavigationDetails";
import DocsState from "../../Documentation/Redux/initState";

const init = {
  UISettings: {
    modals: {
      editDetails: false,
    },

    bannerColor: "red",
    buttonColor: "red",
    secondaryColor: "black",
    primaryColor: "red",

    currency: "USD",
    language: "en",
    defaultCountryIdx: 1,
    defaultStateIdx: 1,

    flow: "",
    homeView: "",
    theme: "",
    bookingSuccessView: "Default/BookingSuccess",
    footer: "Default",

    isAirLineInfoAvailable: false,
    isReservation: false,
    isVehicleCount: false,
    isAgeOnHome: 1,
    billingAddressInfo: "False",
    isDriversInfoOptional: "False",
    additionalDriver: false,
    navPrice: true,

    parent: "",
    maxCheckinTime: "09:30 AM",
    timeSelection: true,
    dateFormat: "dd-mm-yyyy",
    defaultCheckoutTime: "09:00 AM",
    defaultCheckinTime: "09:00 AM",
    driverLicense: "1",
    paymentInfo: "0",
    timeGap: 15,
    showClock: "Y",
    navBar: "CompactNavbar",
    showTermsAfter: 2,
    showSignInAfter: -1,
    minimumDriverAge: 18,
    prePaymentMessage: "",
    distanceUnit: "km",
    customerInfoScreenMessage: "",
    addOnsScreenMessage: "",
    noteFields: "",
    alwaysSamePickAndDrop: false,
    hideDistanceAllowed: false,

    logo: "",
    config: {},
    enableEditFormDetails: true,
    isAdvanceFromVehicleType: false,
    showPickupDropoffAddress: {},
    showInsuranceFields: false,
    hideOrDisableTimePicker: "",
    minimumBookingDays: "",
    maximumBookingDays: "",
    minimumBookingHours: null,
    earliestPickup: "",
    showVehiclesLeft: false,
    showCheckAvailability: false,
    showDOB: true,
    showLogoOnHome: false,
    homeScreenMessage: "",
    hideVehicleFeature: false,
    hideLicenseIssueDate: false,
    showPromoCode: true,
    showOptSms: false,
    showOptEmail: false,
    summaryView: "Summary",
    showMonthYear: "False",
    hideLicenseExpiryDate: true,
    maxQuantityForMisc: 100,
    cutOffTime: null,
    showMonthYear: false,
    isPaymentInfoMandatory: true,
    canPayLater: false,
    isNearestHour: false,
    DropDownClock: false,
    ShowSendQuote: false,
    showDriverLicence: false,
    showDriverInsurance: false,
    addDaysForCheckout: -1,
    InsuranceCardMessages: "",
    hideLicenseIssueDateInAdditional: false,
    hideLicenseExpiryDateInAdditional: false,
    minimumBookingDaysFromConfig: "",
    HideSimilarToText: false,
    PayMeLink: "",
    displayBaseRateWithTax: false,
    BankAccountNumber: "",
    NoVehicleDataImage: "",
    NoVehicleDataMessage: "",
    DriverInsuranceFormHeading: "",
    CustomDriverAges: "",
    displayDiscountBaseRate: false,
  },
  trackingSettings: {},
  locations: [],
  storeHours: [],
  storeHours_differentDropOff: [],
  price: [],
  sublocations: [],
  form: {
    location: "",
    differentDropOff: false,
    pickUpDate: new Date(),
    returnDate: new Date(),
    pickUpTime: new Date(),
    returnTime: new Date(),
    age: "",
    differentDropOffLocation: "",
    selectedVehicle: { VehicleTypeId: null },
    selectedMisc: [],
    selectedInventory: [],
    promoCode: "",
    VehicleId: "",

    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",

    dateOfBirth: "",
    driverLicNumber: "",
    driverLicExp: "",
    driverLicIssueDate: "",

    additionalDriversFName: "",
    additionalDriversLName: "",
    additionalDriversNumber: "",
    additionalDriversEmail: "",
    additionalDriverDateOfBirth: "",
    additionalDriverLicNumber: "",
    additionalDriverLicExp: "",
    additionalDriverLicIssueDate: "",
    advancePaymentAmount: 0,
    address: "",
    city: "",
    country: "",
    picCountry: "",
    state: "",
    picState: "",
    zipCode: "",
    reservationNumber: null,
    creditCardNumber: "",
    creditCardExpire: "",
    cvv: "",
    cardHolderName: "",
    promoId: "",
    insuranceCompanyName: "",
    isSmsOpted: true,
    isEmailOpted: true,
    squareUpNonce: "",
    expMonth: "",
    expYear: "",
    last4: "",
    cardBrand: "",
    uploadImageSet: [],
    vehicleTypeId: "",
    makeID: "",
    price: 0.0,
    totalCost: 0.0,
    seats: 0,
    baggages: 0,
    doors: 0,
    sortDirection: "",
    totMonths: "",
    makeName: "",
    enableBookButton: false,
    selectedMake: [],
    enableEditButton: false,
    secondRedirection: false,
    subDropOffLocation: "",
    subPickUpLocation: "",
    subDropOffLocationName: "",
    subPickUpLocationName: "",
    dropOffHotelName: "",
    pickUpHotelName: "",
    selectedVehilceImage: null,
    selectedVehilceName: null,
  },

  availableVehicles: [],
  vehiclesLoaded: false,
  availableVehicleItems: [],
  availableVehicleTypeItems: [],

  paymentConfiguration: {
    SquareupClientId: 0,
    SquareupLocationId: 0,
  },

  terms: [],

  imports: {
    homePage: Loading,
    flow: [],
    dynamicStyle: {},
    bookingSuccessComponent: Loading,
    navbar: CompactNavbar,
    footer: Loading,
  },
  auth: {
    token: null,
  },
  summery: {
    baseRate: 0,
    totalDays: 0,
    totalMiscChargesTaxable: 0,
    totalMiscChargesNonTaxable: 0,
    subTotal: 0,
    totalTax: 0,
    total: 0,
    promotionDiscount: 0,
    rateSummaryItems: [],
    avgPerDayRate: 0,
    miscCharges: [],
    inventoryCharges: [],
    taxes: [],
    securityDeposit: 0,
    promotionDiscountOnSubTotal: 0,
  },
  config: {
    navbar: {
      steppers: [],
      custom: false,
    },
    vehicleDetails: {
      summery: "",
      included: "",
    },
  },
  miscCharges: [],
  taxes: [],
  vehicleTypes: [],
  vehicleMakes: [],
};
function isJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}
export default function mainReducer(state = init, action) {
  switch (action.type) {
    case ActionType.SET_STORE_HOURS: {
      return {
        ...state,
        storeHours: action.data.storeHours,
      };
    }
    case ActionType.SET_STORE_HOURS_DIFFERENT_DROP_LOCATION: {
      return {
        ...state,
        storeHours_differentDropOff: action.data.storeHours_differentDropOff,
      };
    }
    case ActionType.CHANGE_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
          [action.data.key]: action.data.value,
        },
      };
    }

    case ActionType.CHANGE_ADDITIONAL_DRIVER: {
      return {
        ...state,
        form: {
          ...state.form,
          additionalDrivers: state.form.additionalDrivers
            ? {
                ...state.form.additionalDrivers,
                [action.data.i]: {
                  ...state.form.additionalDrivers[action.data.i],
                  [action.data.key]: action.data.value,
                },
              }
            : { [action.data.i]: { [action.data.key]: action.data.value } },
        },
      };
    }

    case ActionType.CLEAR_VEHICLE_TYPES: {
      return {
        ...state,
        availableVehicles: [],
        vehiclesLoaded: false,
      };
    }

    case ActionType.ADD_MISC: {
      let newMisc = [...state.form.selectedMisc];
      newMisc.push(action.data.misc);

      return {
        ...state,
        form: {
          ...state.form,
          selectedMisc: newMisc,
        },
      };
    }
    case ActionType.ADD_INVENTORY: {
      let newInventory = [...state.form.selectedInventory];
      newInventory.push(action.data.inventory);

      return {
        ...state,
        form: {
          ...state.form,
          selectedInventory: newInventory,
        },
      };
    }
    case ActionType.UPDATE_MISC: {
      let miscId = action.data.misc.Id;
      let newMisc = [...state.form.selectedMisc];

      return {
        ...state,
        form: {
          ...state.form,
          selectedMisc: newMisc.map((m) =>
            m.Id === miscId
              ? {
                  ...m,
                  Quantity: action.data.quantity,
                  StartDate: action.data.startDate,
                  EndDate: action.data.endDate,
                }
              : m
          ),
        },
      };
    }
    case ActionType.UPDATE_INVENTORY: {
      let invId = action.data.inv.Id;
      let newInventory = [...state.form.selectedInventory];

      return {
        ...state,
        form: {
          ...state.form,
          selectedInventory: newInventory.map((m) =>
            m.Id === invId
              ? {
                  ...m,
                  Quantity: action.data.quantity,
                  StartDate: action.data.startDate,
                  EndDate: action.data.endDate,
                }
              : m
          ),
        },
      };
    }
    case ActionType.REMOVE_MISC: {
      let newMisc = [...state.form.selectedMisc];
      const { miscId } = action.data;

      newMisc = newMisc.filter((item) => item.Id !== miscId);

      return {
        ...state,
        form: {
          ...state.form,
          selectedMisc: newMisc,
        },
      };
    }

    case ActionType.REMOVE_INVENTORY: {
      let newInventory = [...state.form.selectedInventory];
      const { inventoryId } = action.data;

      newInventory = newInventory.filter((item) => item.Id !== inventoryId);

      return {
        ...state,
        form: {
          ...state.form,
          selectedInventory: newInventory,
        },
      };
    }

    case ActionType.RESET_MISC: {
      return {
        ...state,
        form: {
          ...state.form,
          selectedMisc: [],
        },
      };
    }

    case ActionType.SET_PRICE: {
      let price = [...state.price];
      price[action.data.position || state.UISettings.currentActiveFlow] =
        action.data.price;
      return {
        ...state,
        price: price,
      };
    }

    case ActionType.CLEAR_PRICE: {
      let price = [...state.price];
      price = price.map(() => 0);

      return {
        ...state,
        price: price,
      };
    }

    case ActionType.ADD_PRICE: {
      let price = [...state.price];
      price[state.UISettings.currentActiveFlow] += action.data.price;
      return {
        ...state,
        price: price,
      };
    }

    case ActionType.IMPORT_NAVBAR: {
      return {
        ...state,
        imports: {
          ...state.imports,
          navbar: action.data.component,
        },
      };
    }

    case ActionType.TOGGLE_MODAL: {
      return {
        ...state,
        UISettings: {
          ...state.UISettings,
          modals: {
            ...state.UISettings.modals,
            [action.data.key]: action.data.value,
          },
        },
      };
    }

    case ActionType.IMPORT_HOME_COMPONENT: {
      return {
        ...state,
        imports: {
          ...state.imports,
          homePage: action.data.component,
        },
      };
    }

    case ActionType.IMPORT_BOOKING_SUCCESS_COMPONENT: {
      return {
        ...state,
        imports: {
          ...state.imports,
          bookingSuccessComponent: action.data.component,
        },
      };
    }

    case ActionType.IMPORT_FOOTER: {
      return {
        ...state,
        imports: {
          ...state.imports,
          footer: action.data.component,
        },
      };
    }

    case ActionType.IMPORT_SUMMARY: {
      return {
        ...state,
        imports: {
          ...state.imports,
          summary: action.data.component,
        },
      };
    }

    case ActionType.IMPORT_FLOW_COMPONENT: {
      let index = state.UISettings.flow
        .split("-")
        .indexOf(action.data.componentName);
      let bb = [...state.imports.flow];

      bb[index] = action.data.component;

      return {
        ...state,
        imports: {
          ...state.imports,
          flow: [...bb],
        },
      };
    }

    case ActionType.IMPORT_STYLES: {
      return {
        ...state,
        imports: {
          ...state.imports,
          dynamicStyle: action.data.component,
        },
      };
    }

    case ActionType.REFRESH_TOKEN: {
      return {
        ...state,
        auth: {
          ...state.auth,
          token: action.data.token,
        },
      };
    }

    case ActionType.SET_DOCS: {
      return {
        ...DocsState,
      };
    }

    case ActionType.FETCH_CLIENT: {
      let price = [];
      let flowArr = action.data.Flow.split("-").map((_) => {
        price.push(0);
        return null;
      });

      return {
        ...state,
        price: [...price],
        locations: action.data.locations,
        storeHours: action.data.storeHours || [],
        storeHours_differentDropOff:
          action.data.storeHours_differentDropOff || [],
        storeHolidays: action.data.storeHolidays || [],
        sublocations: action.data.sublocations || [],
        UISettings: {
          ...state.UISettings,
          bannerColor: action.data.BannerColor || "red",
          buttonColor: action.data.ButtonColor || "red",
          clientId: action.data.ClientId,
          theme: action.data.Theme || "default",
          homeView: action.data.HomeView || "Default/IndexRentCar",
          bookingSuccessView:
            action.data.BookingSuccessView || "Default/BookingSuccess",
          primaryColor: action.data.PredominantColor || "red",
          secondaryColor: action.data.SecondaryColor || "red",
          dateFormat: action.data.DateFormat || "dd/MM/yyyy",
          language: action.data.Language || "en",
          flow: action.data.Flow || "Flow1-Flow2-Flow3",
          defaultCheckoutTime: action.data.DefaultCheckoutTime || "06:00 PM",
          defaultCheckinTime: action.data.DefaultCheckinTime || "09:00 AM",
          currency: action.data.Currency || "Currency Not defined",
          defaultCountryIndex: action.data.DefaultCountryIdx || 1,
          defaultStateIndex: action.data.DefaultStateIdx || 1,
          isAirLineInfoAvailable: false,
          isReservation: false,
          isVehicleCount: false,
          maxCheckinTime: action.data.MaxCheckinTime,
          parent: action.data.Parent || "",
          currentActiveFlow: 0,
          showStepper:
            action.data.ShowNavigationStepper === "True" ? true : false,
          minimumDriverAge: parseInt(action.data.MinimumDriverAge) || 18,
          prePaymentMessage: action.data.PrePaymentMessage || "",
          customerInfoScreenMessage:
            action.data.CustomerInfoScreenMessage || "",
          addOnsScreenMessage: action.data.AddOnsScreenMessage || "",
          noteFields: action.data.NoteFields
            ? isJSON(action.data.NoteFields)
              ? JSON.parse(action.data.NoteFields)
              : action.data.NoteFields
            : null,
          vehicleSortBy: action.data.VehicleSortBy || null,
          vehicleSortDirection: action.data.VehicleSortDirection || null,
          distanceUnit: action.data.DistanceUnit || "km",
          footer: action.data.Footer || "Default",
          additionalDriver:
            action.data.ShowAdditionalDriverFields === "True" || false,
          alwaysSamePickAndDrop:
            action.data.AlwaysSamePickAndDrop === "True" || false,
          hideDistanceAllowed:
            action.data.ShowDistanceAllowed === "False" || false,
          timeSelection: action.data.TimeSelection || false,
          isAgeOnHome: action.data.IsAgeOnHome || "False",
          driverLicense: action.data.ShowLicenseFields || "False",
          paymentInfo: action.data.ShowPaymentFields || "False",
          billingAddressInfo: action.data.ShowBillingAddressFields || "False",
          showClock: action.data.ShowClock || "Y",
          isDriversInfoOptional: action.data.isDriversInfoOptional || "False",
          showTermsAfter: parseInt(action.data.ShowTermsAfter) || 2,
          showSignInAfter: parseInt(action.data.ShowSignInAfter) || -1,
          timeGap: parseInt(action.data.TimeGap) || 15,
          logo: action.data.Logo || "",
          navBar: action.data.NavBar || "Default/DefaultFlowNavbar",
          vehicleTypes: action.data.vehicleTypes || [],
          isAdvanceFromVehicleType:
            action.data.isAdvanceFromVehicleType || false,
          showPickupDropoffAddress: action.data.ShowPickupDropoffAddress || {},
          showInsuranceFields: action.data.ShowInsuranceFields || false,
          hideOrDisableTimePicker: action.data.HideOrDisableTimePicker || "",
          minimumBookingDays: action.data.MinimumBookingDays || "",
          maximumBookingDays: action.data.MaximumBookingDays || "",
          minimumBookingHours: action.data.MinimumBookingHours || null,
          earliestPickup: action.data.EarliestPickup || 0,
          showVehiclesLeft: action.data.ShowVehiclesLeft === "True" || false,
          showCheckAvailability:
            action.data.ShowCheckAvailability === "True" || false,
          showDOB: action.data.ShowBirthday === "True" || false,
          showLogoOnHome: action.data.ShowLogoOnHome === "True" || false,
          homeScreenMessage: action.data.HomeScreenMessage || "",
          hideVehicleFeature:
            action.data.HideVehicleFeature === "True" || false,
          hideLicenseIssueDate:
            action.data.HideLicenseIssueDate === "True" || false,
          showPromoCode: action.data.ShowPromoCode === "True",
          showOptSms: action.data.ShowOptSms === "True",
          showOptEmail: action.data.ShowOptEmail === "True",
          summaryView: action.data.Summary || "Summary",
          hideLicenseExpiryDate:
            action.data.HideLicenseExpiryDate === "True" || false,
          showMonthYear: action.data.showMonthYear === "True" || "False",
          maxQuantityForMisc: parseInt(action.data.MaxQuantityForMisc) || 100,
          cutOffTime: action.data.CutOffTime || null,
          isPaymentInfoMandatory: action.data.IsPaymentInfoMandatory === "True",
          canPayLater: action.data.CanPayLater === "True",
          isNearestHour: action.data.IsNearestHour === "True",
          DropDownClock: action.data.DropDownClock === "True" || "False",
          ShowSendQuote: action.data.ShowSendQuote === "True" || false,
          showDriverLicence: action.data.ShowDriverLicence === "True",
          showDriverInsurance: action.data.ShowDriverInsurance === "True",
          addDaysForCheckout: parseInt(action.data.addDaysForCheckout) || -1,
          SaveUntilPaySuccess: action.data.SaveUntilPaySuccess === "True",
          InsuranceCardMessages: action.data.InsuranceCardMessages
            ? isJSON(action.data.InsuranceCardMessages)
              ? JSON.parse(action.data.InsuranceCardMessages)
              : action.data.InsuranceCardMessages
            : null,
          hideLicenseIssueDateInAdditional:
            action.data.HideLicenseIssueDateInAdditional === "True" || false,
          hideLicenseExpiryDateInAdditional:
            action.data.HideLicenseExpiryDateInAdditional === "True" || false,
          DrivingLicenseMessage: action.data.DrivingLicenseMessage
            ? isJSON(action.data.DrivingLicenseMessage)
              ? JSON.parse(action.data.DrivingLicenseMessage)
              : action.data.DrivingLicenseMessage
            : null,
          vehicleMakes: action.data.vehicleMakes || [],
          renamePerDay: action.data.RenamePerDay === "per night" ? true : false,
          minimumBookingDaysFromConfig:
            action.data.Config?.flow?.getMinimumBookingDays || "",
          ShowVehicleGroup:
            action.data.ShowVehicleGroup === "True" ? true : false,
          HideSecurityDeposit:
            action.data.HideSecurityDeposit === "True" ? true : false,
          hideTotalCharge:
            action.data.HideTotalCharge === "True" ? true : false,
          HideSimilarToText:
            action.data.HideSimilarToText === "True" ? true : false,
          displayBaseRateWithTax:
            action.data.DisplayBaseRateWithTax === "True" ? true : false,
          PayMeLink: action.data.PayMeLink || null,
          BankAccountNumber: action.data.BankAccountNumber || null,
          NoVehicleDataImage: action.data.NoVehicleDataImage || null,
          NoVehicleDataMessage: action.data.NoVehicleDataMessage || null,
          DriverInsuranceFormHeading:
            action.data.DriverInsuranceFormHeading || null,
          CustomDriverAges: action.data.CustomDriverAges || null,
          displayDiscountBaseRate:
            action.data.DisplayDiscountBaseRate === "True" ? true : false,
          clientBookingURL: action.data.ClientBookingURL || "",
        },
        config:
          {
            ...action.data.Config,
            vehicleDetails: {
              ...(action.data.Config.vehicleDetails || {}),
            },
          } || {},

        imports: {
          ...state.imports,
          flow: flowArr,
        },
        form: {
          ...state.form,
          country: parseInt(action.data.DefaultCountryIdx) || null,
          picCountry: parseInt(action.data.DefaultCountryIdx) || null,
          state: parseInt(action.data.DefaultStateIdx) || null,
          picState: parseInt(action.data.DefaultStateIdx) || null,
          hideState: action.data.DefaultStateIdx,
          advancePaymentAmount: action.data.AdvancePaymentAmount,
        },
        paymentSettings: {
          transactionType: action.data.TransactionType,
          advancePaymentAmount: action.data.AdvancePaymentAmount,
        },
        trackingSettings: {
          googleTagContainerId: action.data.GoogleTagContainerId,
        },
        vehicleTypes: action.data.vehicleTypes,
        vehicleMakes: action.data.vehicleMakes,
      };
    }

    case ActionType.SET_VEHICLES: {
      return {
        ...state,
        availableVehicles: action.data.vehicles,
        vehiclesLoaded: true,

        form: {
          ...state.form,
          selectedMisc: [],
          //    selectedVehicle: { VehicleTypeId: null }
        },
      };
    }

    case ActionType.CLEAR_VEHICLE_ITEMS: {
      return {
        ...state,
        availableVehicleItems: [],
      };
    }

    case ActionType.SET_VEHICLE_ITEMS: {
      return {
        ...state,
        availableVehicleItems: action.data.vehicles,
      };
    }

    case ActionType.SET_SUB_LOCATION: {
      return {
        ...state,
        sublocations: action.data.sublocations,
      };
    }
    case ActionType.CLEAR_VEHICLE_TYPE_ITEMS: {
      return {
        ...state,
        availableVehicleTypeItems: [],
      };
    }

    case ActionType.SET_VEHICLETYPE_ITEMS: {
      return {
        ...state,
        availableVehicleTypeItems: action.data.vehicles || [],
      };
    }

    case ActionType.SET_MAKE: {
      return {
        ...state,
        vehicleMakes: action.data.makes,
      };
    }

    case ActionType.SET_MISC: {
      return {
        ...state,
        miscCharges: action.data.miscCharges || [],
      };
    }

    case ActionType.SET_INVENTORY: {
      return {
        ...state,
        inventoryCharges: action.data.inventoryCharges || [],
      };
    }

    case ActionType.SET_VEHICLEMAKE_ITEMS: {
      return {
        ...state,
        selectedMake: action.data.vehicles || [],
      };
    }

    case ActionType.CHANGE_UISETTINGS: {
      return {
        ...state,
        UISettings: {
          ...state.UISettings,
          [action.data.key]: action.data.value,
        },
      };
    }

    case ActionType.SET_TAXES: {
      return {
        ...state,
        taxes: action.data.taxes || [],
      };
    }

    case ActionType.SET_SUMMERY: {
      return {
        ...state,
        summery: action.data.summery,
        form: {
          ...state.form,
          promoId:
            state.form.promoId != ""
              ? state.form.promoId
              : action.data.summery.promotions != null &&
                action.data.summery.promotions.length > 0
              ? [action.data.summery.promotions[0].promotionId]
              : [state.form.promoId],
          promoCode:
            action.data.summery.promotions != null &&
            action.data.summery.promotions.length > 0
              ? action.data.summery.promotions[0].promotionCode
              : state.form.promoCode,
        },
      };
    }

    case ActionType.SET_MULTI_VEHICLE_SUMMERY: {
      return {
        ...state,
        multiVehicleSummary: action.data.summary,
      };
    }

    case ActionType.SET_TERMS: {
      return {
        ...state,
        terms: action.data.terms,
      };
    }

    // ************** Documentation ****************

    case ActionType.SET_STEPPER: {
      return {
        ...state,
        config: {
          ...state.config,
          navbar: { ...state.config.navbar, steppers: action.data.steppers },
        },
      };
    }

    case ActionType.SET_PAYMENT_CONFIGURATION: {
      return {
        ...state,
        paymentConfiguration: action.data.data,
      };
    }

    case ActionType.ADD_IMAGE: {
      let imageArray = [...state.form.uploadImageSet];
      imageArray = imageArray.filter(
        (item) => item.ImageType !== action.data.image.ImageType
      );
      imageArray.push(action.data.image);
      imageArray = imageArray.filter((item) => item.ImageBlob !== "");

      return {
        ...state,
        form: {
          ...state.form,
          uploadImageSet: imageArray,
        },
      };
    }

    default: {
      return state;
    }
  }
}
