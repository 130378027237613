import ActionsType from "./ActionsType";
import ErrorTypes from "../../tools/ErrorTypes";
import mainAxios from "../../Axios/Axios";
import store from "../createStore";
import { format } from "date-fns";

import { importStyles, importFooter, importSummary } from "./importActions";
import {
  changeForm,
  setPrice,
  addMisc,
  addPrice,
  addInventory,
} from "./formActions";
import { setSummery, setMultiVehicleSummary } from "./summeryActions";
import { alert } from "./UIActions";
import creditCardType, {
  getTypeInfo,
  types as CardType,
} from "credit-card-type";
import { fetchCountries, fetchStates } from "../../tools/api";
import generator from "generate-password";

const generatePassword = () => {
  const pwd = generator.generate({
    length: 10,
    lowercase: true,
    uppercase: true,
    numbers: true,
    symbols: true,
  });
  return pwd;
};

const fetchClient = (clientid) => {
  return async (dispatch) => {
    try {
      const clientReq = await mainAxios.get(
        `${process.env.REACT_APP_RESERVATION_API_BASE_URL}reservation/initdata?clientid=${clientid}`
      );

      let url = `/api/v3/Clients/${clientid}/ClientFeatures`;

      const clientFeatures = await mainAxios.post(url);

      let payload = {};

      clientReq.data.allSettings.forEach((item) => {
        payload[item.keyValue] = item.value;
      });

      payload["clientFeatures"] = clientFeatures.data;

      payload["locations"] = clientReq.data.locations;
      payload["storeHours"] = clientReq.data.storeHours;
      payload["storeHolidays"] = clientReq.data.storeHolidays.filter(
        (item) => item.isAcceptReservations == false
      );
      payload["vehicleTypes"] = clientReq.data.vehicleTypes || [];
      payload["vehicleMakes"] = clientReq.data.vehicleMakes || [];
      payload["sublocations"] = clientReq.data.subLocation || [];
      payload.DateFormat = payload.DateFormat.replaceAll("-", "/")
        .replace("DD", "dd")
        .replace("YYYY", "yyyy");

      const defaultConfig = {
        navbar: {
          custom: true,
          steppers: [
            { title: "Select Vehicle", type: "Selected Vehicle", icon: "car" },
            {
              title: "Vehicle Details",
              type: "Vehicle Details",
              icon: "detail",
            },
            { title: "Extras", type: "Add Ons", icon: "plus-square" },
            { title: "Insurance", type: "Insurance", icon: "info" },
            { title: "Info", type: "User info", icon: "user-info" },
            { title: "Checkout", type: "Checkout", icon: "credit-card" },
          ],
        },
      };

      payload.Config = payload.Config
        ? JSON.parse(payload.Config)
        : defaultConfig;
      payload.ShowPickupDropoffAddress = payload.ShowPickupDropoffAddress
        ? JSON.parse(payload.ShowPickupDropoffAddress)
        : null;

      dispatch({ type: ActionsType.FETCH_CLIENT, data: payload });

      dispatch(importStyles(payload.Theme));
      dispatch(importFooter(payload["Footer"]));
      dispatch(importSummary(payload["summaryView"]));

      if (payload.locations.length === 1) {
        dispatch(changeForm("location", payload.locations[0].locationId));
        dispatch(
          changeForm(
            "differentDropOffLocation",
            payload.locations[0].locationId
          )
        );
      }

      if (payload.sublocations.length === 1) {
        dispatch(
          changeForm("subPickUpLocation", payload.sublocations[0].locationId)
        );
        dispatch(
          changeForm("subDropOffLocation", payload.sublocations[0].locationId)
        );
      }
      localStorage.removeItem("reservation_plugin_client_features");
      window.localStorage.setItem(
        "reservation_plugin_client_features",
        JSON.stringify(payload)
      );
    } catch (err) {
      console.log(err);
      alert("Error Occured while feptching the user data.");
    }
  };
};

const fetchStoreHours = (locationId) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      let url = `/api/v3/Clients/${state.UISettings.clientId
        }/StoreHours?LocationId=${locationId || state.form.location}`;

      const storeHours = await mainAxios.get(url);

      dispatch({
        type: ActionsType.SET_STORE_HOURS,
        data: { storeHours: storeHours.data },
      });
    } catch (err) {
      console.log(err);
      alert("Error Occured while fetching Store Hours");
    }
  };
};

const fetchReturnLocationsStoreHours = (locationId) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      let url = `/api/v3/Clients/${state.UISettings.clientId
        }/StoreHours?LocationId=${locationId || state.form.differentDropOffLocation
        }`;

      const storeHours = await mainAxios.get(url);

      dispatch({
        type: ActionsType.SET_STORE_HOURS_DIFFERENT_DROP_LOCATION,
        data: { storeHours_differentDropOff: storeHours.data },
      });
    } catch (err) {
      console.log(err);
      alert("Error Occured while fetching Store Hours");
    }
  };
};

const fetchVehicleType = (pickDate, dropOffDate) => {
  return async (dispatch) => {
    try {
      const state = store.getState();
      let pickUpDate = pickDate || state.form.pickUpDate;
      let returnDate = dropOffDate || state.form.returnDate;

      let { returnTime, pickUpTime, location } = state.form;

      let vehicleSortBy = state.UISettings.VehicleSortBy || "Rate";
      let vehicleSortDirection = state.UISettings.vehicleSortDirection || "ASC";
      let vehicleTypeId = state.form.vehicleTypeId || "";
      let reservationTypeName = "Online";
      let customerTypeName = "Online";
      let customerAge = state.form.age || 18;
      let promId = state.form.promoId || [];
      const checkOut =
        format(pickUpDate, "yyyy-MM-dd").toString() +
        "T" +
        format(pickUpTime, "HH:mm").toString();
      const checkIn =
        format(returnDate, "yyyy-MM-dd").toString() +
        "T" +
        format(returnTime, "HH:mm").toString();

      const lang = new URLSearchParams(window.location.search).get("lang");

      let url = `/api/v3/vehicletypes/?SortBy=${vehicleSortBy}&SortDirection=${vehicleSortDirection}&ClientId=${state.UISettings.clientId}&LocationId=${location}&StartDate=${checkOut}&EndDate=${checkIn}&IsOnline=true
      &reservationTypeName=${reservationTypeName}&customerTypeName=${customerTypeName}&customerAge=${customerAge}`;
      if (promId.length > 0) {
        url += "&PromotionIds=" + [promId];
      }
      if (lang) {
        url += "&Language=" + lang;
      }

      const vehicleReq = await mainAxios.get(url);

      if (vehicleReq.data.length === 0) {
        dispatch({
          type: ActionsType.SET_VEHICLES,
          data: { vehicles: vehicleReq.data },
        });
        throw ErrorTypes.NO_VEHICLE_AVAILABLE;
      } else {
        dispatch({
          type: ActionsType.SET_VEHICLES,
          data: { vehicles: vehicleReq.data },
        });
      }
    } catch (err) {
      if (err === ErrorTypes.NO_VEHICLE_AVAILABLE) {
        throw ErrorTypes.NO_VEHICLE_AVAILABLE;
      }
      console.log(err);
    }
  };
};

const fetchVehicles = (checkAvailableVehicle) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      let pickUpDate = state.form.pickUpDate;
      let returnDate = state.form.returnDate;
      let vehicleTypeId = state.form.selectedVehicle.VehicleTypeId;

      let { returnTime, pickUpTime, location } = state.form;

      const checkOut =
        format(pickUpDate, "yyyy-MM-dd").toString() +
        "T" +
        format(pickUpTime, "HH:mm").toString();
      const checkIn =
        format(returnDate, "yyyy-MM-dd").toString() +
        "T" +
        format(returnTime, "HH:mm").toString();

      const lang = new URLSearchParams(window.location.search).get("lang");

      let url = `/api/v3/vehicles/?ClientId=${state.UISettings.clientId}&active=true&IsOnline=true&CurrentLocationId=${location}&StartDate=${checkOut}&EndDate=${checkIn}&IsOnline=true&VehicleTypeId=${vehicleTypeId}&CheckAvailableVehicle=${checkAvailableVehicle}`;

      if (lang) {
        url += "&Language=" + lang;
      }
      const vehicleReq = await mainAxios.get(url);

      if (vehicleReq.data.length === 0) {
        throw ErrorTypes.NO_VEHICLE_ITEM_AVAILABLE;
      } else {
        dispatch({
          type: ActionsType.SET_VEHICLE_ITEMS,
          data: { vehicles: vehicleReq.data },
        });
      }
    } catch (err) {
      if (err === ErrorTypes.NO_VEHICLE_AVAILABLE) {
        throw ErrorTypes.NO_VEHICLE_AVAILABLE;
      }
      console.log(err);
    }
  };
};

const fetchVehiclesMake = (makeId, vehicleTypeId) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      // let pickUpDate = pickDate || state.form.pickUpDate;
      // let returnDate = dropOffDate || state.form.returnDate;
      let pickUpDate = state.form.pickUpDate;
      let returnDate = state.form.returnDate;

      let { returnTime, pickUpTime } = state.form;

      let vehicleSortBy = state.UISettings.VehicleSortBy || "Rate";
      let vehicleSortDirection = state.form.sortDirection || "ASC";

      const checkOut =
        format(pickUpDate, "yyyy-MM-dd").toString() +
        "T" +
        format(pickUpTime, "HH:mm").toString();
      const checkIn =
        format(returnDate, "yyyy-MM-dd").toString() +
        "T" +
        format(returnTime, "HH:mm").toString();

      let vehicleTypeId = state.form.vehicleTypeId || "";
      let makeId = state.form.makeID || "";
      // state.form.selectedVehicle.VehicleTypeId = state.form.vehicleTypeId;
      let location =
        state.locations[0].locationId ||
        store.config.flow?.locations?.locationId;
      state.form.location = location;

      const lang = new URLSearchParams(window.location.search).get("lang");
      let url1 = `/api/v3/vehicletypes/?SortBy=${vehicleSortBy}&SortDirection=${vehicleSortDirection}&ClientId=${state.UISettings.clientId}&LocationId=${state.form.location}&StartDate=${checkOut}&EndDate=${checkIn}&IsOnline=true&VehicleTypeID=${vehicleTypeId}`;

      let url = `/api/v3/vehicles/?ClientId=${state.UISettings.clientId}&active=true&IsOnline=true&CurrentLocationId=${state.form.location}&MakeId=${makeId}&IsOnline=true&VehicleTypeId=${vehicleTypeId}&PageSize=200`;

      if (lang) {
        url += "&Language=" + lang;
        url1 += "&Language=" + lang;
      }

      const vehicleReq = await mainAxios.get(url);
      const vehicletypeReq = await mainAxios.get(url1);
      const vehicleTypeRes = vehicletypeReq.data;
      // state.availableVehicleTypeItems=[];
      // if (
      //   state.form.vehicleTypeId !== null &&
      //   state.form.vehicleTypeId !== ""
      // ) {
      //   if (state.availableVehicleTypeItems.length > 0) {
      //     let itevehType = state.availableVehicleTypeItems.find(
      //       (vehtype) => vehtype.VehicleTypeId === state.form.vehicleTypeId
      //     );

      //     state.form.price = itevehType.Price;
      //     state.form.totalCost = itevehType.FullValue;
      //   }
      // }

      // state.form.makeName='llll';
      if (state.form.makeID !== null && state.form.makeID !== "") {
        let selectedMake = state.vehicleMakes.find(
          (e) => e.id === state.form.makeID
        );
        // console.log('qweqwe',selectedMake);
        if (selectedMake !== "" && selectedMake !== null) {
          state.form.makeName = selectedMake.value;
        }
      }
      if (vehicletypeReq.data.length === 0) {
        throw ErrorTypes.NO_VEHICLE_AVAILABLE;
      } else {
        if (state.availableVehicleTypeItems.length === 0) {
          dispatch({
            type: ActionsType.SET_VEHICLETYPE_ITEMS,
            data: { vehicles: vehicletypeReq.data },
          });
        }
      }
      if (vehicleReq.data.length === 0) {
        throw ErrorTypes.NO_VEHICLE_ITEM_AVAILABLE;
      } else {
        dispatch({
          type: ActionsType.SET_VEHICLE_ITEMS,
          data: { vehicles: vehicleReq.data },
        });
      }
    } catch (err) {
      if (err === ErrorTypes.NO_VEHICLE_AVAILABLE) {
        throw ErrorTypes.NO_VEHICLE_AVAILABLE;
      }
      console.log(err);
    }
  };
};

const fetchInventoryCharges = (VehicleTypeId) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      VehicleTypeId = VehicleTypeId || state.form.selectedVehicle.VehicleTypeId;

      const lang = new URLSearchParams(window.location.search).get("lang");

      let url = `/api/v3/Inventory/Groups/?ClientId=${state.UISettings.clientId
        }&IsOnline=${true}&locationid=${state.form.location
        }&VehicleTypeId=${VehicleTypeId}`;

      if (lang) {
        url += "&Language=" + lang;
      }

      const inventoryChargesReq = await mainAxios.get(url);

      const inventoryCharges = inventoryChargesReq.data;

      const selectedInventory = state.form.selectedInventory || [];

      if (inventoryChargesReq.length === 0)
        throw ErrorTypes.NO_INVENTORY_AVAILABLE;

      inventoryCharges.forEach((item) => {
        const isAlreadySelected = !!selectedInventory.find(
          (inventory) => inventory.Id === item.Id
        );

        if (!isAlreadySelected) {
          if (!item.IsOptional) {
            if (item.IsQuantity) {
              item = { ...item, Quantity: 1 };
            }
            dispatch(addInventory(item));
          }
        } else {
          dispatch(addPrice(item.total));
        }
      });

      dispatch({ type: ActionsType.SET_INVENTORY, data: { inventoryCharges } });
    } catch (err) {
      if (err === ErrorTypes.NO_INVENTORY_AVAILABLE) {
        throw ErrorTypes.NO_INVENTORY_AVAILABLE;
      }
      console.log(err);
    }
  };
};

const fetchMiscCharges = (VehicleTypeId) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      VehicleTypeId = VehicleTypeId || state.form.selectedVehicle.VehicleTypeId;

      const lang = new URLSearchParams(window.location.search).get("lang");

      var CheckoutDate =
        format(state.form.pickUpDate, "yyyy-MM-dd").toString() +
        "T" +
        format(state.form.pickUpTime, "HH:mm").toString();
      var CheckInDate =
        format(state.form.returnDate, "yyyy-MM-dd").toString() +
        "T" +
        format(state.form.returnTime, "HH:mm").toString();
      //let url = `/api/v3/MiscCharges/?ClientId=${state.UISettings.clientId}&IsOnline=${true}&locationid=${state.form.location}&VehicleTypeId=${VehicleTypeId}`;
      let url = `/api/v3/MiscCharges/?ClientId=${state.UISettings.clientId
        }&IsOnline=${true}&locationid=${state.form.location
        }&VehicleTypeId=${VehicleTypeId}&CheckOutDate=${CheckoutDate}&CheckInDate=${CheckInDate}`;

      if (lang) {
        url += "&Language=" + lang;
      }

      const miscChargesReq = await mainAxios.get(url);
      // const miscChargesReq = await mainAxios.get(`/api/v3/MiscCharges/?ClientId=${state.UISettings.clientId}&locationid=${state.form.location}&VehicleTypeId=${state.form.selectedVehicle.VehicleTypeId}`);

      const miscCharges = miscChargesReq.data;

      const selectedMisc = state.form.selectedMisc || [];

      if (miscChargesReq.length === 0) throw ErrorTypes.NO_MISC_AVAILABLE;

      miscCharges.forEach((item) => {
        const isAlreadySelected = !!selectedMisc.find(
          (misc) => misc.Id === item.Id
        );

        if (!isAlreadySelected) {
          if (!item.IsOptional) {
            dispatch(addMisc(item));
          }
        } else {
          dispatch(addPrice(item.total));
        }
      });

      dispatch({ type: ActionsType.SET_MISC, data: { miscCharges } });
    } catch (err) {
      if (err === ErrorTypes.NO_MISC_AVAILABLE) {
        throw ErrorTypes.NO_MISC_AVAILABLE;
      }
      console.log(err);
    }
  };
};

const getCustomer = () => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const payload = {
        clientId: state.UISettings.clientId,
        username: state.form.email,
        password: state.form.password,
      };

      const customer = await mainAxios.post(`api/v3/customers/login`, payload);

      if (customer) {
        dispatch(changeForm("customerId", customer.data.customerId));
        dispatch(changeForm("firstName", customer.data.firstName));
        dispatch(changeForm("lastName", customer.data.lastName));
        dispatch(changeForm("email", customer.data.email));
        dispatch(changeForm("phoneNumber", customer.data.hPhone));
        dispatch(
          changeForm(
            "dateOfBirth",
            customer.data.dateOfbirth ? new Date(customer.data.dateOfbirth) : ""
          )
        );
        dispatch(changeForm("driverLicNumber", customer.data.licenseNumber));
        dispatch(
          changeForm(
            "driverLicExp",
            customer.data.licenseExpiryDate
              ? new Date(customer.data.licenseExpiryDate)
              : ""
          )
        );
        dispatch(
          changeForm(
            "driverLicIssueDate",
            customer.data.licenseIssueDate
              ? new Date(customer.data.licenseIssueDate)
              : ""
          )
        );
        dispatch(changeForm("address", customer.data.address1));
        dispatch(changeForm("city", customer.data.city));
        dispatch(changeForm("zipCode", customer.data.zipCode));
        dispatch(changeForm("state", customer.data.stateId));
        dispatch(changeForm("country", customer.data.countryId));

        return true;
      }

      return false;
    } catch (err) {
      return false;
    }
  };
};

const getPaymentGatewayDetails = () => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const request = {
        clientId: parseInt(state.UISettings.clientId),
        locationId: state.form.location,
      };

      const paymentConfig = await mainAxios.post(
        `api/v3/Payment/ConfigurationDetails`,
        request
      );

      if (paymentConfig) {
        dispatch({
          type: ActionsType.SET_PAYMENT_CONFIGURATION,
          data: paymentConfig,
        });
        return true;
      }

      return false;
    } catch (err) {
      return false;
    }
  };
};

const fetchTaxes = (
  locationId,
  pickUpDate,
  returnDate,
  pickUpTime,
  returnTime
) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const lang = new URLSearchParams(window.location.search).get("lang");

      let url = `/api/v3/Taxes?ClientId=${state.UISettings.clientId
        }&LocationId=${locationId || state.form.location}`;

      if (lang) {
        url += "&Language=" + lang;
      }

      const taxReq = await mainAxios.get(url);

      dispatch({ type: ActionsType.SET_TAXES, data: { taxes: taxReq.data } });
      dispatch(
        getSummery(locationId, pickUpDate, returnDate, pickUpTime, returnTime)
      );
    } catch (err) {
      console.log(err);
      alert("Error Occured while fetching the Taxes");
    }
  };
};

const getSummery = (
  locationId,
  pickUpDate,
  returnDate,
  pickUpTime,
  returnTime
) => {
  return async (dispatch) => {
    try {
      const state = store.getState();
      const totalPrice = state.price.reduce((total, val) => total + val, 0);

      locationId = locationId || state.form.location;
      let locationToId = state.form.differentDropOffLocation || locationId;
      pickUpDate = pickUpDate || state.form.pickUpDate;
      returnDate = returnDate || state.form.returnDate;

      returnTime = returnTime || state.form.returnTime;
      pickUpTime = pickUpTime || state.form.pickUpTime;

      const lang = new URLSearchParams(window.location.search).get("lang");

      if (state.form.selectedVehicles) {
        let multiVehicleSummary = await getMultiVehicleSummary(
          state,
          locationId,
          locationToId,
          pickUpDate,
          pickUpTime,
          returnDate,
          returnTime,
          lang
        );

        dispatch(setMultiVehicleSummary(multiVehicleSummary));
      } else {
        const payload = {
          ClientId: parseInt(state.UISettings.clientId),
          PromotionIds: state.form.promoId || [],
          LocationId: parseInt(locationId),
          LocationToId: parseInt(locationToId),
          StartDate:
            format(pickUpDate, "yyyy-MM-dd").toString() +
            "T" +
            format(pickUpTime, "HH:mm").toString(),
          EndDate:
            format(returnDate, "yyyy-MM-dd").toString() +
            "T" +
            format(returnTime, "HH:mm").toString(),
          VehicleTypeId: parseInt(state.form.selectedVehicle.VehicleTypeId),
          MiscCharges: state.form.selectedMisc,
          InventoryCharges: state.form.selectedInventory,
          TaxIds: [
            ...state.taxes.reduce((result, item) => {
              result.push(item.id);
              return result;
            }, []),
          ],
          Language: lang,
          IsOnline: true,
        };

        if (state.form.promoId != null && state.form.promoId != "") {
          payload.PromotionIds = state.form.promoId;
        } else {
          if (window.localStorage.getItem("promoId")) {
            state.form.promoId = [
              parseInt(window.localStorage.getItem("promoId")),
            ];
          }
          window.localStorage.setItem("promoId", "");
          if (!isNaN(state.form.promoId) && state.form.promoId != "") {
            payload.PromotionIds = state.form.promoId;
          } else {
            payload.PromotionIds = null;
          }
        }

        payload.reservationTypeName = "Online";
        payload.customerTypeName = "Online";
        payload.customerAge = state.form.age || 18;

        const summeryReq = await mainAxios.post("/api/v3/Summary", payload);

        dispatch(changeForm("miscCharges", summeryReq.data.miscCharges));
        dispatch(setPrice(summeryReq.data.total - totalPrice));
        dispatch(setSummery(summeryReq.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

const getMultiVehicleSummary = async (
  state,
  locationId,
  locationToId,
  pickUpDate,
  pickUpTime,
  returnDate,
  returnTime,
  lang
) => {
  let multiVehicleSummary = [];

  for (const v of state.form.selectedVehicles) {
    const payload = {
      ClientId: parseInt(state.UISettings.clientId),
      PromotionIds: [],
      LocationId: parseInt(locationId),
      LocationToId: parseInt(locationToId),
      StartDate:
        format(pickUpDate, "yyyy-MM-dd").toString() +
        "T" +
        format(pickUpTime, "HH:mm").toString(),
      EndDate:
        format(returnDate, "yyyy-MM-dd").toString() +
        "T" +
        format(returnTime, "HH:mm").toString(),
      VehicleTypeId: parseInt(v.VehicleTypeId),
      MiscCharges: state.form.selectedMisc,
      InventoryCharges: state.form.selectedInventory,
      TaxIds: [
        ...state.taxes.reduce((result, item) => {
          result.push(item.id);
          return result;
        }, []),
      ],
      Language: lang,
      IsOnline: true,
    };

    if (state.form.promoId) payload.PromotionIds = state.form.promoId;

    const summeryReq = await mainAxios.post("/api/v3/Summary", payload);

    multiVehicleSummary.push({
      ...summeryReq.data,
      VehicleTypeName: v.VehicleTypeName,
    });

    console.log(multiVehicleSummary);
  }

  return multiVehicleSummary;
};

const getQuote = () => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const payload = {
        ClientId: parseInt(state.UISettings.clientId),
        StartDate:
          format(state.form.pickUpDate, "yyyy-MM-dd").toString() +
          "T" +
          format(state.form.pickUpTime, "HH:mm").toString(),
        EndDate:
          format(state.form.returnDate, "yyyy-MM-dd").toString() +
          "T" +
          format(state.form.returnTime, "HH:mm").toString(),
        VehicleTypeId: parseInt(state.form.selectedVehicle.VehicleTypeId),
        VehicleId: null,
        CheckoutLocationId: parseInt(state.form.location),
        CheckinLocationId: parseInt(
          state.form.differentDropOff
            ? state.form.differentDropOffLocation
            : state.form.location
        ),
        MiscCharges: state.form.selectedMisc,
        InventoryCharges: state.form.selectedInventory,
        PromotionIds: state.form.promoId || [],
        FirstName: state.form.firstName,
        LastName: state.form.lastName,
        customerPassword: generatePassword(),
        Company: null,
        Phone: state.form.phoneNumber,
        PreAdjustment: state.summery.dropOffCharges,
        email: state.form.email,
        Address: state.form.address,
        City: state.form.city,
        ZipCode: state.form.zipCode,
        StateId: parseInt(state.form.state),
        CountryId: parseInt(state.form.country),
        TaxIds: [
          ...state.taxes.reduce((result, item) => {
            result.push(item.id);
            return result;
          }, []),
        ],
        CreatedDate:
          format(new Date(), "yyyy-MM-dd").toString() +
          "T" +
          format(new Date(), "HH:mm").toString(),
      };

      if (state.UISettings.driverLicense === "1") {
        if (!state.UISettings.hideLicenseExpiryDate === "True") {
          payload["LicenseExpiryDate"] = format(
            state.form.driverLicExp,
            "yyyy-MM-dd"
          ).toString();
        }
        payload["LicenseNumber"] = state.form.driverLicNumber;
        if (!state.UISettings.hideLicenseIssueDate === "True") {
          payload["LicenseIssueDate"] = format(
            state.form.driverLicIssueDate,
            "yyyy-MM-dd"
          ).toString();
        }
      }
      if (state.UISettings.showDOB === true && state.form.dateOfBirth != "") {
        payload["DateOfBirth"] = format(
          state.form.dateOfBirth,
          "yyyy-MM-dd"
        ).toString();
      }

      const submitReq = await mainAxios.post("/api/v3/Quotes", payload);
      return submitReq.data;
    } catch (err) {
      console.log(err);
      alert("Error Occured while booking.");

      return false;
    }
  };
};

const getPaymentLink = () => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const payload = {
        clientId: parseInt(state.UISettings.clientId),
        locationId: parseInt(state.form.location),
        amount:
          state.UISettings.isAdvanceFromVehicleType &&
            state.form.selectedVehicle.Deposit > 0
            ? state.form.selectedVehicle.Deposit
            : state.paymentSettings.advancePaymentAmount > 0
              ? state.paymentSettings.advancePaymentAmount
              : state.summery.total,
      };

      const submitReq = await mainAxios.post("/api/v3/Payment/Link", payload);
      dispatch(changeForm("externalPaymentUrl", submitReq.data.url));
      dispatch(
        changeForm(
          "externalPaymentReferenceNumber",
          submitReq.data.paymentReferenceId
        )
      );

      return submitReq.data;
    } catch (err) {
      console.log(err);
      alert("Error occured while making payment.");

      return false;
    }
  };
};

const createCustomer = () => {
  return async (dispatch) => {
    const state = store.getState();

    let payload = {
      ClientId: parseInt(state.UISettings.clientId),
      FirstName: state.form.firstName,
      LastName: state.form.lastName,
      Company: null,
      HomePhone: state.form.phoneNumber,
      Email: state.form.email,
      customerPassword: generatePassword(),
      Active: true,
      LocationId: parseInt(state.form.location),
      CustomerType: "Online",
    };

    const submitReq = await mainAxios.post("/api/v3/Customers", payload);
    dispatch(changeForm("customerId", submitReq.data));
  };
};

const bookReservation = (sessionId) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      //begin - validate the credit card type
      let cardType = "";
      if (state.form.cardBrand && state.form.cardBrand !== "") {
        cardType = state.form.cardBrand;
      } else if (state.form.creditCardNumber.replaceAll(" ", "") !== "") {
        if (
          state.form.creditCardType !== "" &&
          typeof state.form.creditCardType !== "undefined"
        ) {
          cardType = state.form.creditCardType;
        } else {
          const type = creditCardType(
            state.form.creditCardNumber.replaceAll(" ", "")
          )[0].type;
          cardType = type ? type : "VISA";
        }
      } else {
        cardType = "VISA";
      }
      //end - validate the credit card type

      let states,
        countryName,
        stateName,
        countries = undefined;
      if (
        state.form.picCountry &&
        state.UISettings.showPickupDropoffAddress &&
        Object.keys(state.UISettings.showPickupDropoffAddress).length != 0 &&
        state.UISettings.showPickupDropoffAddress.OnlyForLocations.includes(
          state.form.location.toString()
        ) &&
        state.UISettings.showPickupDropoffAddress !== "False"
      ) {
        countries = await mainAxios.get("/api/v3/Locations/Countries");
        states = await mainAxios.get(
          `/api/v3/Locations/Countries/${state.form.picCountry}/States`
        );
        countryName = countries.data.find(
          (m) => m.countryID === parseInt(state.form.picCountry)
        ).countryName;
        stateName = state.form.picState
          ? states.data.find((m) => m.stateID === parseInt(state.form.picState))
            .stateName
          : "";
      }

      if (state.form.promoId[0] == "['']" || state.form.promoId[0] == "") {
        state.form.promoId = [];
      }
      const payload = {
        ClientId: parseInt(state.UISettings.clientId),
        StartDate:
          format(state.form.pickUpDate, "yyyy-MM-dd").toString() +
          "T" +
          format(state.form.pickUpTime, "HH:mm").toString(),
        EndDate:
          format(state.form.returnDate, "yyyy-MM-dd").toString() +
          "T" +
          format(state.form.returnTime, "HH:mm").toString(),
        VehicleTypeId: parseInt(state.form.selectedVehicle.VehicleTypeId),
        VehicleId: state.form.VehicleId,
        CheckoutLocationId: parseInt(state.form.location),
        CheckinLocationId: parseInt(
          state.form.differentDropOff
            ? state.form.differentDropOffLocation
            : state.form.location
        ),
        MiscCharges: state.form.selectedMisc,
        InventoryCharges: state.form.selectedInventory,
        PromotionIds: state.form.promoId || [],

        // CreditCardType:
        //   state.form.cardBrand && state.form.cardBrand != ""
        //     ? state.form.cardBrand
        //     : state.form.creditCardNumber.replaceAll(" ", "")
        //     ? state.form.creditCardType != ""
        //     : state.form.creditCardType
        //     ? creditCardType(state.form.creditCardNumber.replaceAll(" ", ""))[0]
        //         .type
        //     : "VISA",

        CreditCardType: cardType,
        NameOnCard:
          state.form.cardHolderName != ""
            ? state.form.cardHolderName
            : state.form.nameOnCard,
        CreditCardNo: state.form.creditCardNumber.replaceAll(" ", "") || null,
        CreditCardExpiryYear:
          state.form.expYear && state.form.expYear != ""
            ? state.form.expYear
            : state.form.creditCardExpire
              ? parseInt("20" + state.form.creditCardExpire.split("/")[1])
              : 0,
        CreditCardExpiryMonth:
          state.form.expMonth && state.form.expMonth != ""
            ? state.form.expMonth
            : parseInt(state.form.creditCardExpire.split("/")[0]),
        CreditCardCVSNo: state.form.cvv || null,
        ccvToken: state.form.CVVX || null,
        cardNumberToken: state.form.CCX || null,

        //Begin - For Adyen Payment Gateway
        EncryptedCreditCardNo: state.form.encryptedCreditCardNo,
        EncryptedExpiryYear: state.form.encryptedExpiryYear,
        EncryptedExpiryMonth: state.form.encryptedExpiryMonth,
        //End - For Adyen Payment Gateway

        FirstName: state.form.firstName,
        LastName: state.form.lastName,
        Company: null,
        Phone: state.form.phoneNumber,
        email: state.form.email,
        customerPassword: generatePassword(),
        Address: state.form.address,
        City: state.form.city,
        ZipCode: state.form.zipCode,
        StateId: parseInt(state.form.state),
        CountryId: parseInt(state.form.country),
        SubTotal: state.summery.subTotal,
        TotalTax: state.summery.totalTax,
        IsPayLater: state.form.isPayLater,
        TransactionType: state.paymentSettings.transactionType,
        AdvancePaymentAmount:
          state.UISettings.isAdvanceFromVehicleType &&
            state.form.selectedVehicle.Deposit > 0
            ? state.form.selectedVehicle.Deposit
            : state.paymentSettings.advancePaymentAmount,
        CustomerId: state.form.customerId,
        ExternalPaymentReferenceNumber:
          state.form.externalPaymentReferenceNumber,
        //PreAdjustment: state.summery.dropOffCharges,
        TaxIds: [
          ...state.taxes.reduce((result, item) => {
            result.push(item.id);
            return result;
          }, []),
        ],
        Note:
          (state.form.note ? state.form.note : "") +
          (state.UISettings.showPickupDropoffAddress &&
            state.UISettings.showPickupDropoffAddress !== "False"
            ? (state.form.pickAddress1
              ? " Pickup Address 1: " + state.form.pickAddress1 + " "
              : "") +
            (state.form.picAddress
              ? " Pickup Address : " + state.form.picAddress + " "
              : "") +
            (state.form.pickAddress2
              ? " Pickup Address 2: " + state.form.pickAddress2 + " "
              : "") +
            (state.form.pickCity
              ? "City: " + state.form.pickCity + " "
              : "") +
            (state.form.picCity ? "City: " + state.form.picCity + " " : "") +
            (countryName ? "Country: " + countryName + " " : "") +
            (state.form.picState
              ? "State: " + state.form.picState + " "
              : "") +
            (state.form.pickZipCode ? "Zip: " + state.form.pickZipCode : "") +
            (state.form.picZipCode ? "Zip: " + state.form.picZipCode : "") +
            (state.form.delAddress1
              ? " Delivery Address 1: " + state.form.delAddress1 + " "
              : "") +
            (state.form.delAddress2
              ? " Delivery Address 2: " + state.form.delAddress2 + " "
              : "") +
            (state.form.delCity ? "City: " + state.form.delCity + " " : "") +
            (countryName ? "Country: " + countryName + " " : "") +
            (state.form.delState
              ? "State: " + state.form.delState + " "
              : "") +
            (state.form.delZipCode ? "Zip: " + state.form.delZipCode : "")
            : "") +
          ((state.form.subPickUpLocationName
            ? "Pickup Location Name:" + state.form.subPickUpLocationName + " "
            : "") +
            (state.form.subDropOffLocationName
              ? "DropOff Location Name:" +
              state.form.subDropOffLocationName +
              " "
              : "") +
            (state.form.pickUpHotelName
              ? "PickUp Hotel Name:" + state.form.pickUpHotelName + " "
              : "") +
            (state.form.dropOffHotelName
              ? "DropOff Location Name:" + state.form.dropOffHotelName + " "
              : "")),
        IsOnline: true,
        CreatedDate:
          format(new Date(), "yyyy-MM-dd").toString() +
          "T" +
          format(new Date(), "HH:mm").toString(),
        IsSMSOpted: state.form.isSmsOpted,
        IsEmailOpted: state.form.isEmailOpted,
        Deposit: state.summery.securityDeposit,
        squareUpNonce: state.form.squareUpNonce,
        UploadImageSet: state.form.uploadImageSet,
        isSquarepaynow: state.form.isSquarepaynow
          ? state.form.isSquarepaynow
          : null,
        billingAddress1: state.form.billingAddress1
          ? state.form.billingAddress1
          : state.form.address,
        billingAddress2: null,
        billingCity: state.form.billingCity
          ? state.form.billingCity
          : state.form.city,
        billingStateId: state.form.billingStateId
          ? parseInt(state.form.billingStateId)
          : parseInt(state.form.state),
        billingCountryId: state.form.billingCountryId
          ? parseInt(state.form.billingCountryId)
          : parseInt(state.form.country),
        billingZipCode: state.form.billingZipCode
          ? state.form.billingZipCode
          : state.form.zipCode,
        webXPaySessionId: sessionId,
        PaypalOrderID: state.form.paypalOrderID,
      };

      if (state.UISettings.additionalDriver) {
        if (
          state.form.additionalDriversFName &&
          state.form.additionalDriversLName
        ) {
          payload["AdditionalDrivers"] = [
            {
              firstName: state.form.additionalDriversFName,
              lastName: state.form.additionalDriversLName,
              phone: state.form.additionalDriversNumber,
              email: state.form.additionalDriversEmail,
              licenseIssueDate: state.form.additionalDriverLicIssueDate
                ? format(
                  state.form.additionalDriverLicIssueDate,
                  "yyyy-MM-dd"
                ).toString()
                : null,
              licenseExpiryDate: state.form.additionalDriverLicExp
                ? format(
                  state.form.additionalDriverLicExp,
                  "yyyy-MM-dd"
                ).toString()
                : null,
              dateOfBirth: state.form.additionalDriverDateOfBirth
                ? format(
                  state.form.additionalDriverDateOfBirth,
                  "yyyy-MM-dd"
                ).toString()
                : null,
              licenseNumber: state.form.additionalDriverLicNumber,
              driverType: "Additional",
            },
          ];
        }
      }

      if (state.UISettings.driverLicense === "True") {
        if (!state.UISettings.hideLicenseExpiryDate) {
          payload["LicenseExpiryDate"] = format(
            state.form.driverLicExp,
            "yyyy-MM-dd"
          ).toString();
        }
        payload["LicenseNumber"] = state.form.driverLicNumber;
        if (!state.UISettings.hideLicenseIssueDate) {
          payload["LicenseIssueDate"] = format(
            state.form.driverLicIssueDate,
            "yyyy-MM-dd"
          ).toString();
        }
      }
      if (state.UISettings.showDOB === true) {
        payload["DateOfBirth"] = format(
          state.form.dateOfBirth,
          "yyyy-MM-dd"
        ).toString();
      }

      if (
        state.form.insuranceCompany ||
        state.form.policyNumber ||
        state.form.InsuranceExpiryDate
      ) {
        payload["AgreementInsurance"] = {
          InsuranceCompanyId: state.form.insuranceCompany
            ? parseInt(state.form.insuranceCompany)
            : 0,
          InsuranceCompanyName: state.form.insuranceCompanyName,
          PolicyNo: state.form.policyNumber,
          ExpiryDate: state.form.insuranceExpiryDate
            ? format(state.form.insuranceExpiryDate, "yyyy-MM-dd").toString()
            : null,
        };
      }

      payload.reservationTypeName = "Online";
      payload.customerTypeName = "Online";
      payload.customerAge = state.form.age || 18;

      const submitReq = await mainAxios.post("/api/v3/Reservations", payload);
      dispatch(
        changeForm("reservationNumber", submitReq.data.reservationNumber)
      );
      dispatch(changeForm("reservationId", submitReq.data.reservationId));

      return submitReq.data;
    } catch (err) {
      console.log(err);

      return {
        message:
          "Something went wrong. Reservation was not created. Please call the rental agency.",
      };
    }
  };
};

const bookMultiReservation = (sessionId) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      //begin - validate the credit card type
      let cardType = "";
      if (state.form.cardBrand && state.form.cardBrand !== "") {
        cardType = state.form.cardBrand;
      } else if (state.form.creditCardNumber.replaceAll(" ", "") !== "") {
        if (
          state.form.creditCardType !== "" &&
          typeof state.form.creditCardType !== "undefined"
        ) {
          cardType = state.form.creditCardType;
        } else {
          const type = creditCardType(
            state.form.creditCardNumber.replaceAll(" ", "")
          )[0].type;
          cardType = type ? type : "VISA";
        }
      } else {
        cardType = "VISA";
      }
      //end - validate the credit card type

      let states,
        countryName,
        stateName,
        countries = undefined;
      if (
        state.form.picCountry &&
        state.UISettings.showPickupDropoffAddress &&
        Object.keys(state.UISettings.showPickupDropoffAddress).length != 0 &&
        state.UISettings.showPickupDropoffAddress.OnlyForLocations.includes(
          state.form.location.toString()
        ) &&
        state.UISettings.showPickupDropoffAddress !== "False"
      ) {
        countries = await mainAxios.get("/api/v3/Locations/Countries");
        states = await mainAxios.get(
          `/api/v3/Locations/Countries/${state.form.picCountry}/States`
        );
        countryName = countries.data.find(
          (m) => m.countryID === parseInt(state.form.picCountry)
        ).countryName;
        stateName = state.form.picState
          ? states.data.find((m) => m.stateID === parseInt(state.form.picState))
            .stateName
          : "";
      }

      const payload = {
        ClientId: parseInt(state.UISettings.clientId),
        Reservations: state.form.selectedVehicles.map((v, i) => {
          return {
            FirstName: state.form.additionalDrivers[i].additionalDriversFName,
            LastName: state.form.additionalDrivers[i].additionalDriversLName,
            Phone: state.form.additionalDrivers[i].additionalDriversNumber,
            email: state.form.additionalDrivers[i].additionalDriversEmail,
            customerPassword: generatePassword(),
            Address: state.form.additionalDrivers[i].address,
            City: state.form.additionalDrivers[i].city,
            ZipCode: state.form.additionalDrivers[i].zipCode,
            StateId: parseInt(state.form.additionalDrivers[i].state),
            CountryId: parseInt(state.form.additionalDrivers[i].country),
            licenseNumber:
              state.form.additionalDrivers[i].additionalDriverLicNumber,
            licenseIssueDate: state.form.additionalDrivers[i]
              .additionalDriverLicIssueDate
              ? format(
                state.form.additionalDrivers[i].additionalDriverLicIssueDate,
                "yyyy-MM-dd"
              ).toString()
              : null,
            licenseExpiryDate: state.form.additionalDrivers[i]
              .additionalDriverLicExp
              ? format(
                state.form.additionalDrivers[i].additionalDriverLicExp,
                "yyyy-MM-dd"
              ).toString()
              : null,
            dateOfBirth: state.form.additionalDrivers[i]
              .additionalDriverDateOfBirth
              ? format(
                state.form.additionalDrivers[i].additionalDriverDateOfBirth,
                "yyyy-MM-dd"
              ).toString()
              : null,
            ClientId: parseInt(state.UISettings.clientId),
            StartDate:
              format(state.form.pickUpDate, "yyyy-MM-dd").toString() +
              "T" +
              format(state.form.pickUpTime, "HH:mm").toString(),
            EndDate:
              format(state.form.returnDate, "yyyy-MM-dd").toString() +
              "T" +
              format(state.form.returnTime, "HH:mm").toString(),
            VehicleTypeId: v.VehicleTypeId,
            CheckoutLocationId: parseInt(state.form.location),
            CheckinLocationId: parseInt(
              state.form.differentDropOff
                ? state.form.differentDropOffLocation
                : state.form.location
            ),
            MiscCharges: state.form.selectedMisc,
            InventoryCharges: state.form.selectedInventory,
            PromotionIds: state.form.promoId || [],
            TaxIds: [
              ...state.taxes.reduce((result, item) => {
                result.push(item.id);
                return result;
              }, []),
            ],
          };
        }),

        // CreditCardType:
        //   state.form.cardBrand && state.form.cardBrand != ""
        //     ? state.form.cardBrand
        //     : state.form.creditCardNumber.replaceAll(" ", "")
        //     ? state.form.creditCardType != ""
        //     : state.form.creditCardType
        //     ? creditCardType(state.form.creditCardNumber.replaceAll(" ", ""))[0]
        //         .type
        //     : "VISA",

        CreditCardType: cardType,
        NameOnCard:
          state.form.cardHolderName != ""
            ? state.form.cardHolderName
            : state.form.nameOnCard,
        CreditCardNo: state.form.creditCardNumber.replaceAll(" ", "") || null,
        CreditCardExpiryYear:
          state.form.expYear && state.form.expYear != ""
            ? state.form.expYear
            : state.form.creditCardExpire
              ? parseInt("20" + state.form.creditCardExpire.split("/")[1])
              : 0,
        CreditCardExpiryMonth:
          state.form.expMonth && state.form.expMonth != ""
            ? state.form.expMonth
            : parseInt(state.form.creditCardExpire.split("/")[0]),
        CreditCardCVSNo: state.form.cvv || null,

        //Begin - For Adyen Payment Gateway
        EncryptedCreditCardNo: state.form.encryptedCreditCardNo,
        EncryptedExpiryYear: state.form.encryptedExpiryYear,
        EncryptedExpiryMonth: state.form.encryptedExpiryMonth,
        //End - For Adyen Payment Gateway

        FirstName: state.form.firstName,
        LastName: state.form.lastName,
        Company: null,
        Phone: state.form.phoneNumber,
        Email: state.form.email,
        customerPassword: generatePassword(),
        Address: state.form.address,
        City: state.form.city,
        ZipCode: state.form.zipCode,
        StateId: parseInt(state.form.state),
        CountryId: parseInt(state.form.country),

        CustomerId: state.form.customerId,

        Note:
          (state.form.note ? state.form.note : "") +
          (state.UISettings.showPickupDropoffAddress &&
            state.UISettings.showPickupDropoffAddress !== "False"
            ? (state.form.pickAddress1
              ? " Pickup Address 1: " + state.form.pickAddress1 + " "
              : "") +
            (state.form.picAddress
              ? " Pickup Address : " + state.form.picAddress + " "
              : "") +
            (state.form.pickAddress2
              ? " Pickup Address 2: " + state.form.pickAddress2 + " "
              : "") +
            (state.form.pickCity
              ? "City: " + state.form.pickCity + " "
              : "") +
            (state.form.picCity ? "City: " + state.form.picCity + " " : "") +
            (countryName ? "Country: " + countryName + " " : "") +
            (state.form.picState
              ? "State: " + state.form.picState + " "
              : "") +
            (state.form.pickZipCode ? "Zip: " + state.form.pickZipCode : "") +
            (state.form.picZipCode ? "Zip: " + state.form.picZipCode : "") +
            (state.form.delAddress1
              ? " Delivery Address 1: " + state.form.delAddress1 + " "
              : "") +
            (state.form.delAddress2
              ? " Delivery Address 2: " + state.form.delAddress2 + " "
              : "") +
            (state.form.delCity ? "City: " + state.form.delCity + " " : "") +
            (countryName ? "Country: " + countryName + " " : "") +
            (state.form.delState
              ? "State: " + state.form.delState + " "
              : "") +
            (state.form.delZipCode ? "Zip: " + state.form.delZipCode : "")
            : ""),
        IsOnline: true,
        CreatedDate:
          format(new Date(), "yyyy-MM-dd").toString() +
          "T" +
          format(new Date(), "HH:mm").toString(),
        IsSMSOpted: state.form.isSmsOpted,
        IsEmailOpted: state.form.isEmailOpted,
        UploadImageSet: state.form.uploadImageSet,
        billingAddress1: state.form.billingAddress1
          ? state.form.billingAddress1
          : state.form.address,
        billingAddress2: null,
        billingCity: state.form.billingCity
          ? state.form.billingCity
          : state.form.city,
        billingStateId: state.form.billingStateId
          ? parseInt(state.form.billingStateId)
          : parseInt(state.form.state),
        billingCountryId: state.form.billingCountryId
          ? parseInt(state.form.billingCountryId)
          : parseInt(state.form.country),
        billingZipCode: state.form.billingZipCode
          ? state.form.billingZipCode
          : state.form.zipCode,
        PaypalOrderID: state.form.paypalOrderID,
      };

      if (state.UISettings.driverLicense === "True") {
        if (!state.UISettings.hideLicenseExpiryDate === "True") {
          payload["LicenseExpiryDate"] = format(
            state.form.driverLicExp,
            "yyyy-MM-dd"
          ).toString();
        }
        payload["LicenseNumber"] = state.form.driverLicNumber;
        if (!state.UISettings.hideLicenseIssueDate === "True") {
          payload["LicenseIssueDate"] = format(
            state.form.driverLicIssueDate,
            "yyyy-MM-dd"
          ).toString();
        }
      }
      if (state.UISettings.showDOB === true) {
        payload["DateOfBirth"] = format(
          state.form.dateOfBirth,
          "yyyy-MM-dd"
        ).toString();
      }

      if (
        state.form.insuranceCompany ||
        state.form.policyNumber ||
        state.form.InsuranceExpiryDate
      ) {
        payload["AgreementInsurance"] = {
          InsuranceCompanyId: state.form.insuranceCompany
            ? parseInt(state.form.insuranceCompany)
            : 0,
          InsuranceCompanyName: state.form.insuranceCompanyName,
          PolicyNo: state.form.policyNumber,
          ExpiryDate: state.form.insuranceExpiryDate
            ? format(state.form.insuranceExpiryDate, "yyyy-MM-dd").toString()
            : null,
        };
      }

      const submitReq = await mainAxios.post(
        "/api/v3/Reservations/Multi",
        payload
      );
      dispatch(changeForm("reservations", submitReq.data.reservations));

      return submitReq.data;
    } catch (err) {
      console.log(err);
      alert("Error Occured while booking.");

      return false;
    }
  };
};

const makePayment = (sessionId) => {
  return async (dispatch) => {
    try {
      const state = store.getState();
      //begin - validate the credit card type
      let cardType = "";
      if (state.form.cardBrand && state.form.cardBrand !== "") {
        cardType = state.form.cardBrand;
      } else if (state.form.creditCardNumber.replaceAll(" ", "") !== "") {
        if (
          state.form.creditCardType !== "" &&
          typeof state.form.creditCardType !== "undefined"
        ) {
          cardType = state.form.creditCardType;
        } else {
          const type = creditCardType(
            state.form.creditCardNumber.replaceAll(" ", "")
          )[0].type;
          cardType = type ? type : "VISA";
        }
      } else {
        cardType = "VISA";
      }
      //end - validate the credit card type

      // const payload = {
      //     ClientId: parseInt(state.UISettings.clientId),
      //     CreditCardType: (state.form.creditCardNumber.replaceAll(" ", "")) ? creditCardType(state.form.creditCardNumber.replaceAll(" ", ""))[0].type : "VISA",
      //     NameOnCard: state.form.cardHolderName,
      //     CreditCardNo: state.form.creditCardNumber ? state.form.creditCardNumber.replaceAll(" ", "") : null,
      //     CreditCardExpiryYear: parseInt("20" + state.form.creditCardExpire.split("/")[1]),
      //     CreditCardExpiryMonth: parseInt(state.form.creditCardExpire.split("/")[0]),
      //     CreditCardCVSNo: state.form.cvv ? state.form.cvv : null,
      //     FirstName: state.form.firstName,
      //     LastName: state.form.lastName,
      //     Email: state.form.email,
      //     BillingAddress1: state.form.address,
      //     BillingAddress2: null,
      //     BillingCity: state.form.city,
      //     BillingZipCode: state.form.zipCode,
      //     BillingStateId: parseInt(state.form.state),
      //     BillingCountryId: parseInt(state.form.country),
      //     SubTotal:state.summery.subTotal,
      //     TotalTax:state.summery.totalTax,
      //     TransactionType: state.paymentSettings.transactionType,
      //     AdvancePaymentAmount: (state.UISettings.isAdvanceFromVehicleType && state.form.selectedVehicle.Deposit > 0) ?  state.form.selectedVehicle.Deposit : state.paymentSettings.advancePaymentAmount,
      //     ReservationId: state.form.reservationId,
      //     ExternalPaymentReferenceNumber: state.form.externalPaymentReferenceNumber,
      //     Deposit :  state.summery.securityDeposit
      // }

      const payload = {
        clientId: parseInt(state.UISettings.clientId),

        // creditCardType:
        //   state.form.cardBrand && state.form.cardBrand != ""
        //     ? state.form.cardBrand
        //     : state.form.creditCardNumber.replaceAll(" ", "")
        //     ? state.form.creditCardType != ""
        //     : state.form.creditCardType
        //     ? creditCardType(state.form.creditCardNumber.replaceAll(" ", ""))[0]
        //         .type
        //     : "VISA",

        creditCardType: cardType,
        nameOnCard:
          state.form.cardHolderName != ""
            ? state.form.cardHolderName
            : state.form.nameOnCard,
        creditCardNo: state.form.creditCardNumber.replaceAll(" ", "") || null,
        creditCardExpiryYear:
          state.form.expYear && state.form.expYear != ""
            ? state.form.expYear
            : state.form.creditCardExpire
              ? parseInt("20" + state.form.creditCardExpire.split("/")[1])
              : 0,
        creditCardExpiryMonth:
          state.form.expMonth && state.form.expMonth != ""
            ? state.form.expMonth
            : parseInt(state.form.creditCardExpire.split("/")[0]),
        creditCardCVSNo: state.form.cvv || null,

        //Begin - For Adyen Payment Gateway
        EncryptedCreditCardNo: state.form.encryptedCreditCardNo,
        EncryptedExpiryYear: state.form.encryptedExpiryYear,
        EncryptedExpiryMonth: state.form.encryptedExpiryMonth,
        //End - For Adyen Payment Gateway

        billingAddress1: state.form.billingAddress1
          ? state.form.billingAddress1
          : state.form.address,
        billingAddress2: null,
        billingCity: state.form.billingCity
          ? state.form.billingCity
          : state.form.city,
        billingStateId: state.form.billingStateId
          ? parseInt(state.form.billingStateId)
          : parseInt(state.form.state),
        billingCountryId: state.form.billingCountryId
          ? parseInt(state.form.billingCountryId)
          : parseInt(state.form.country),
        billingZipCode: state.form.billingZipCode
          ? state.form.billingZipCode
          : state.form.zipCode,
        locationId: parseInt(state.form.location),
        customerId: state.form.customerId || 0,
        firstName: state.form.firstName,
        lastName: state.form.lastName,
        email: state.form.email,
        invoiceId: 0,
        agreementId: 0,
        reservationId: state.form.reservationId || 0,
        advancePaymentAmount:
          state.UISettings.isAdvanceFromVehicleType &&
            state.form.selectedVehicle.Deposit > 0
            ? state.form.selectedVehicle.Deposit
            : state.paymentSettings.advancePaymentAmount,
        externalPaymentReferenceNumber:
          state.form.externalPaymentReferenceNumber || 0,
        CreditCardId: 0,
        achToken: null,
        ccvToken: state.form.CVVX || null,
        cardNumberToken: state.form.CCX || null,
        webXPaySessionId: sessionId,
        phone: state.form.phoneNumber,
        PaypalOrderID: state.form.paypalOrderID,
      };

      const submitReq = await mainAxios.post("/api/v3/Payment", payload);

      return submitReq.data;
    } catch (err) {
      console.log(err);
      alert("Error Occured while booking.");

      return false;
    }
  };
};

const sendEmailAlert = () => {
  return async (dispatch) => {
    try {
      const state = store.getState();
      let ClientId = state.UISettings.clientId;
      let pickUpDate = state.form.pickUpDate;
      let returnDate = state.form.returnDate;

      let locName = state.locations.find(
        (locId) => locId.locationId === state.form.location
      );
      let SubLocationName = state.sublocations.find(
        (pickId) => pickId.subLocationId === state.form.subDropOffLocation
      );

      let subPickUpLocationName = state.sublocations.find(
        (dropId) => dropId.subLocationId === state.form.subPickUpLocation
      );
      state.form.subPickUpLocationName = subPickUpLocationName.subLocationName;
      state.form.subDropOffLocationName = SubLocationName.subLocationName;
      const checkOut =
        format(state.form.pickUpDate, "yyyy-MM-dd").toString() +
        " " +
        format(state.form.pickUpTime, "HH:mm a").toString();
      const checkIn =
        format(state.form.returnDate, "yyyy-MM-dd").toString() +
        " " +
        format(state.form.returnTime, "HH:mm a").toString();
      // const url = ``;state.form.pickUpDate '<br/>'
      const emailbody =
        "Location:" +
        locName.locationName +
        " <br/> Pickup date : " +
        checkOut +
        "<br/>Return date : " +
        checkIn +
        "<br/>Pickup location : " +
        subPickUpLocationName.subLocationName +
        "<br/>Pickup Hotel : " +
        state.form.pickUpHotelName +
        "<br/> DropOff location :" +
        SubLocationName.subLocationName +
        "<br/>DropOff Hotel : " +
        state.form.dropOffHotelName;
      let subject = "Email alert of papa rental customer" + state.form.email;
      const payload = {
        clientId: parseInt(state.UISettings.clientId),
        to: [],
        cc: [],
        emailBody: emailbody,
        subject: subject,
        fromEmail: null,
        sentDate: state.form.pickUpDate,
        isCustomerALert: true,
        locationid: state.form.location,
        // PickupHotel: state.form.location ? state.form.location : null,
        // DropOffHotel: state.form.location ? state.form.location : null,
      };

      const submitReq = await mainAxios.post(
        "/api/v3/Emails/CustomerEntryEmail",
        payload
      );

      return submitReq.data;
    } catch (err) {
      console.log(err);
      alert("Error Occured while sending Email.");

      return false;
    }
  };
};

const createPaypalOrder = (sessionId) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const payload = {
        clientId: parseInt(state.UISettings.clientId),
        creditCardType: state.form.creditCardNumber.replaceAll(" ", "")
          ? creditCardType(state.form.creditCardNumber.replaceAll(" ", ""))[0]
            .type
          : "VISA",
        creditCardNo: state.form.creditCardNumber
          ? state.form.creditCardNumber.replaceAll(" ", "")
          : null,
        creditCardExpiryYear: state.form.creditCardExpire
          ? parseInt("20" + state.form.creditCardExpire.split("/")[1])
          : 0,
        creditCardExpiryMonth: parseInt(
          state.form.creditCardExpire.split("/")[0]
        ),
        creditCardCVSNo: state.form.cvv ? state.form.cvv : null,
        nameOnCard: state.form.cardHolderName,
        billingAddress1: state.form.billingAddress1
          ? state.form.billingAddress1
          : state.form.address,
        billingAddress2: null,
        billingCity: state.form.billingCity
          ? state.form.billingCity
          : state.form.city,
        billingStateId: state.form.billingStateId
          ? parseInt(state.form.billingStateId)
          : parseInt(state.form.state),
        billingCountryId: state.form.billingCountryId
          ? parseInt(state.form.billingCountryId)
          : parseInt(state.form.country),
        billingZipCode: state.form.billingZipCode
          ? state.form.billingZipCode
          : state.form.zipCode,
        locationId: parseInt(state.form.location),
        customerId: state.form.customerId || 0,
        firstName: state.form.firstName,
        lastName: state.form.lastName,
        email: state.form.email,
        invoiceId: 0,
        agreementId: 0,
        reservationId: state.form.reservationId || 0,
        advancePaymentAmount:
          state.UISettings.isAdvanceFromVehicleType &&
            state.form.selectedVehicle.Deposit > 0
            ? state.form.selectedVehicle.Deposit
            : state.paymentSettings.advancePaymentAmount,
        externalPaymentReferenceNumber:
          state.form.externalPaymentReferenceNumber || 0,
        CreditCardId: 0,
        achToken: null,
        ccvToken: state.form.CVVX || null,
        cardNumberToken: state.form.CCX || null,
        webXPaySessionId: sessionId,
        phone: state.form.phoneNumber,
      };

      const submitReq = await mainAxios.post(
        "/api/v3/Payment/PayPalOrder",
        payload
      );

      return submitReq.data;
    } catch (err) {
      console.log(err);
      alert("Error Occured while calling PayPal 3D Authorization.");

      return false;
    }
  };
};

const fetchTerms = (clientId) => {
  return async (dispatch) => {
    try {
      const lang = new URLSearchParams(window.location.search).get("lang");
      const params = new URLSearchParams();

      clientId = clientId || store.getState().UISettings.clientId;
      params.append("Language", "all");

      const url = `/api/v3/Clients/${clientId}/Terms`;
      const urlWithParams = `${url}?${params.toString()}`;

      // if(lang){
      //     url += '&Language=' + lang;
      //  }

      const termsReq = await mainAxios.get(urlWithParams);

      dispatch({ type: ActionsType.SET_TERMS, data: { terms: termsReq.data } });
    } catch (err) {
      console.log(err);
      alert("Error Occured while fetching the terms.");
    }
  };
};

const verifyPromotion = (
  clientId,
  promotionCode,
  startingDate,
  endingDate,
  locationId
) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      if (!!!state.form.promoCode) return;

      clientId = parseInt(clientId || state.UISettings.clientId);
      promotionCode = promotionCode || state.form.promoCode;
      startingDate =
        startingDate ||
        format(state.form.pickUpDate, "yyyy-MM-dd").toString() +
        "T" +
        format(state.form.pickUpTime, "HH:mm").toString();
      endingDate =
        endingDate ||
        format(state.form.returnDate, "yyyy-MM-dd").toString() +
        "T" +
        format(state.form.returnTime, "HH:mm").toString();
      locationId = locationId || state.form.location;

      const promotionVerifyReq = await mainAxios.get(
        `/api/v3/Promotions/?ClientId=${clientId}&PromotionCode=${promotionCode}&EndDate=${endingDate}&StartDate=${startingDate}&LocationId=${locationId}`
      );

      // dispatch(changeForm('promoCode', state.form.promoCode + '(((' + promotionVerifyReq.data[0]["promotionId"]));
      dispatch(
        changeForm("promoId", [promotionVerifyReq.data[0]["promotionId"]])
      );
      dispatch(fetchTaxes());
      dispatch(alert("success", "Promotion added successfully", 3000));
    } catch (err) {
      console.log(err);
      dispatch(alert("error", "Invalid Promotion Code", 3000));
      dispatch(changeForm("promoCode", ""));

      throw ErrorTypes.INVALID_PROMOCODE;
    }
  };
};

const getVehicleMakes = () => {
  // const getPaymentLink = () => {
  // const url = new URL(`${baseUrl}/VehicleMakes`);
  return async (dispatch) => {
    try {
      const state = store.getState();
      let clientId = state.UISettings.clientId;
      let vehicelTypeId = state.form.vehicleTypeId;

      const url = `/api/v3/Vehicles/VehicleMake/?ClientId=${clientId}&isOwnedOnly=false&isOnline=true&Typeid=${vehicelTypeId}`;

      // const url = `api/v3/Vehicles/VehicleMake`;
      // url.searchParams.append("clientId", clientId);
      // url.searchParams.append("isOnline", clientId);
      // url.searchParams.append("Typeid", state.form.vehicelTypeId);

      const makeReq = await mainAxios.get(url);

      dispatch({ type: ActionsType.SET_MAKE, data: { makes: makeReq.data } });
    } catch (err) {
      console.log(err);
      alert("Error Occured while fetching the Makes.");
    }
  };
};

const checkVehiclesMake = () => {
  return async (dispatch) => {
    try {
      const state = store.getState();
      let pickUpDate = state.form.pickUpDate;
      let returnDate = state.form.returnDate;

      let { returnTime, pickUpTime } = state.form;
      const checkOut =
        format(pickUpDate, "yyyy-MM-dd").toString() +
        "T" +
        format(pickUpTime, "HH:mm").toString();
      const checkIn =
        format(returnDate, "yyyy-MM-dd").toString() +
        "T" +
        format(returnTime, "HH:mm").toString();

      let vehicleTypeId = state.form.vehicleTypeId;
      let makeId = state.form.makeID;
      state.form.selectedVehicle.VehicleTypeId = state.form.vehicleTypeId;
      let location =
        state.locations[0].locationId ||
        store.config.flow?.locations?.locationId;
      state.form.location = location;
      let vehicleId = state.form.VehicleId;

      const lang = new URLSearchParams(window.location.search).get("lang");
      // let url = `/api/v3/vehicles/?ClientId=${state.UISettings.clientId}&active=true&IsOnline=true&CurrentLocationId=${state.form.location}&MakeId=${makeId}&IsOnline=true&VehicleTypeId=${vehicleTypeId}`;
      let url = `/api/v3/vehicles/?ClientId=${state.UISettings.clientId}&active=true&IsOnline=true&CurrentLocationId=${state.form.location}&MakeId=${makeId}&IsOnline=true&VehicleTypeId=${vehicleTypeId}&CheckAvailableVehicle=true&StartDate=${checkOut}&EndDate=${checkIn}&VehicleId=${vehicleId}`;

      if (lang) {
        url += "&Language=" + lang;
      }

      const vehicleReq = await mainAxios.get(url);

      if (vehicleReq.data.length === 0) {
        throw ErrorTypes.NO_VEHICLE_AVAILABLE;
      } else {
        if (vehicleId != "") {
          let itevehType = vehicleReq.data[0];
          state.form.selectedVehicle.VehicleTypeId = itevehType.VehicleTypeId;
          state.form.vehicleTypeId = itevehType.VehicleTypeId;
          state.form.makeID = itevehType.MakeId;
          state.form.makeName = itevehType.VehicleMakeName;
        }
        dispatch({
          type: ActionsType.SET_VEHICLE_ITEMS,
          data: { vehicles: vehicleReq.data },
        });
      }
    } catch (err) {
      if (err === ErrorTypes.NO_VEHICLE_AVAILABLE) {
        // alert("No vehicles available");
        dispatch(
          alert(
            "error",
            "No Vehicles available. Please Select different Vehicle",
            3000
          )
        );
        throw ErrorTypes.NO_VEHICLE_AVAILABLE;
      }
      console.log(err);
    }
  };
};

const getSubLocations = () => {
  // const getPaymentLink = () => {
  // const url = new URL(`${baseUrl}/VehicleMakes`);
  return async (dispatch) => {
    try {
      const state = store.getState();
      let ClientId = state.UISettings.clientId;
      let LocationId = state.form.location;

      const url = `api/v3/Locations/SubLocation?clientId=${ClientId}&locationId=${LocationId}`;

      // const url = `api/v3/Vehicles/VehicleMake`;
      // url.searchParams.append("clientId", clientId);
      // url.searchParams.append("isOnline", clientId);
      // url.searchParams.append("Typeid", state.form.vehicelTypeId);

      const makeReq = await mainAxios.get(url);

      dispatch({
        type: ActionsType.SET_SUB_LOCATION,
        data: { sublocations: makeReq.data },
      });
    } catch (err) {
      console.log(err);
      alert("Error Occured while fetching the sub-location.");
    }
  };
};

export {
  fetchClient,
  fetchStoreHours,
  fetchReturnLocationsStoreHours,
  fetchVehicleType,
  fetchVehicles,
  fetchMiscCharges,
  fetchInventoryCharges,
  fetchTaxes,
  createCustomer,
  getCustomer,
  bookReservation,
  bookMultiReservation,
  getPaymentLink,
  getQuote,
  makePayment,
  createPaypalOrder,
  fetchTerms,
  verifyPromotion,
  getPaymentGatewayDetails,
  fetchVehiclesMake,
  getVehicleMakes,
  checkVehiclesMake,
  getSubLocations,
  sendEmailAlert,
};
