import axios from "axios";
import store from "../redux/createStore";

const mainAxios = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

mainAxios.interceptors.request.use(function (config) {
  const params = new URLSearchParams(window.location.search);

  config.headers.Authorization = `Bearer ${store.getState().auth.token}`;
  config.headers["X-clientId"] = params.get("clientid");

  return config;
});

export default mainAxios;
