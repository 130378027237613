import mainAxios from "../Axios/Axios";
import store from "../redux/createStore";

async function fetchCountries() {
  try {
    const countryReq = await mainAxios.get("/api/v3/Locations/Countries");
    return countryReq.data;
  } catch (err) {
    console.log(err);
    alert("Error Occured while fetching the countries ");
    return [];
  }
}

async function fetchStates(countryId) {
  try {
    if (countryId) {
      const stateReq = await mainAxios.get(
        `/api/v3/Locations/Countries/${countryId}/States`
      );
      return stateReq.data;
    }

    return [];
  } catch (err) {
    console.log(err);
    alert("Error Occured while fetching the state ");
    return [];
  }
}

async function fetchAvailability(
  vehicletypeid,
  clientid,
  locationid,
  VehicleId
) {
  try {
    const state = store.getState();

    vehicletypeid = vehicletypeid || state.form.selectedVehicle.VehicleTypeId;
    clientid = clientid || state.UISettings.clientId;
    locationid = locationid || state.form.location;
    let vehicleIdPart = VehicleId ? `&VehicleId=${VehicleId}` : "";

    const availability = await mainAxios.get(
      `/api/v3/vehicleTypes/${vehicletypeid}/availability?ClientId=${clientid}&LocationId=${locationid}${vehicleIdPart}`
    );

    return availability.data;
  } catch (err) {
    console.log(err);
  }
}

async function fetchInsuranceCompanies(clientId) {
  try {
    clientId = clientId || store.getState().UISettings.clientId;

    const insuranceReq = await mainAxios.get(
      `/api/v3/InsuranceCompanies/?clientid=${clientId}`
    );

    return insuranceReq.data;
  } catch (err) {
    console.log(err);
  }
}

async function fetchCardKey() {
  try {
    const clientId = store.getState().UISettings.clientId;

    const insuranceReq = await mainAxios.get(
      `/api/v3/Payment/CardknoxPublicKey?clientid=${clientId}`
    );

    return insuranceReq.data;
  } catch (err) {
    console.log(err);
  }
}

async function getPaymentGatewayDetails() {
  try {
    const state = store.getState();
    const request = {
      clientId: parseInt(store.getState().UISettings.clientId),
      locationId: state.form.location,
    };

    const paymentConfig = await mainAxios.post(
      `api/v3/Payment/ConfigurationDetails`,
      request
    );
    return paymentConfig.data;
  } catch (err) {
    console.log(err);
    alert("Error Occured while calling Get Payment Gateway Details.");
    return [];
  }
}

export {
  fetchCountries,
  fetchStates,
  fetchAvailability,
  fetchInsuranceCompanies,
  fetchCardKey,
  getPaymentGatewayDetails,
};
