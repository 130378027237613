import React from 'react';
import { Route, Redirect } from "react-router-dom";
import PermissionDened from '../components/Static/PermissionDenied/PermissionDenied';

const PrivateRoute = ({ component: Component, auth, to, ...rest }) => (
    <Route {...rest} render={(props) => (
        auth
            ? <Component {...props} />
            : (!!to)? <Redirect to={to} />: <PermissionDened />
    )} />
);

export default PrivateRoute;
