import store from "../redux/createStore";
import {
  getTime,
  startOfTomorrow,
  isBefore,
  addDays,
  addHours,
  addMinutes,
  format,
  isPast,
  parse,
} from "date-fns";
import Payment from "payment";
import { changeStepper } from "../redux/actions/UIActions";

const jumpToSteps = (
  clientid = new URLSearchParams(window.location.search).get("clientid"),
  lang = new URLSearchParams(window.location.search).get("lang"),
  pagePath,
  clientdomain
) => {
  let { form } = store.getState();
  let state = store.getState();
  const formdetails = {
    age: form.age,
    differentDropOffLocation: form.differentDropOffLocation,
    differentDropOff : form.differentDropOffLocation,
    pickUpDate: form.pickUpDate,
    pickUpTime: form.pickUpTime,
    returnDate: form.returnDate,
    returnTime: form.returnTime,
    location: form.location,
    totMonths: form.totMonths,
    makeID: form.makeID,
    enableEditButton: form.enableEditButton,
    secondRedirection: form.secondRedirection,
    VehicleId: form.VehicleId,
    selectedVehicle: form.selectedVehicle,
    vehicleTypeId: form.vehicleTypeId,
    makeName: form.makeName,
    subDropOffLocation: form.subDropOffLocation,
    subPickUpLocation: form.subPickUpLocation,
    subDropOffLocationName: form.subDropOffLocationName,
    subPickUpLocationName: form.subPickUpLocationName,
    dropOffHotelName: form.dropOffHotelName,
    pickUpHotelName: form.pickUpHotelName,
    sortDirection: form.sortDirection,
    promoId: form.promoId,
  };
  let urlParams = new URLSearchParams(window.location.search);
  const customerId = urlParams.get("customerId");

  if (customerId) {
    form = { ...form, customerId };
    formdetails = { ...formdetails, customerId };
  }

  // const crypt = btoa(JSON.stringify(form.location,form.pickUpDate,form.returnDate,form.age,form.promoCode,form.pickUpTime,form.returnTime));
  //const crypt = btoa(JSON.stringify(formdetails));
  const crypt = Buffer.from(JSON.stringify(formdetails)).toString("base64");

  let pickupDate = format(new Date(formdetails.pickUpDate), "yyyy-MM-dd");

  let pickupTime = format(new Date(formdetails.pickUpTime), "hh:mm");

  let returnDate = format(new Date(formdetails.returnDate), "yyyy-MM-dd");

  let returnTime = format(new Date(formdetails.returnTime), "hh:mm");

  let pickupLocation = "";
  let dropOffLocation = "";

  state.locations.forEach(({ locationId, locationName }) => {
    if (locationId === formdetails.location) {
      pickupLocation = locationName.replace("&", "");
    } else if (
      formdetails.differentDropOff &&
      locationId === formdetails.differentDropOffLocation
    ) {
      dropOffLocation = locationName.replace("&", "");
    }
  });

  let vehilceTypeId = "";
  state.vehicleTypes.forEach(({ id, value }) => {
    if (id == formdetails.vehicleTypeId) {
      vehilceTypeId = `&vehicle_type=${value.toLowerCase()}`;
    }
  });

  dropOffLocation = dropOffLocation || pickupLocation;

  if (form.enableOpenNewTab) {
    let url1 = "";
    form.enableOpenNewTab = false;
    if (lang) {
      url1 = `${
        window.location.origin
      }/flow/${pagePath}/?pickup_location=${pickupLocation}&dropoff_location=${dropOffLocation}&pickup_date=${pickupDate}&pickup_time=${pickupTime}&dropoff_date=${returnDate}&dropoff_time=${returnTime}${
        vehilceTypeId != "" ? vehilceTypeId : ""
      }&clientid=${clientid}&lang=${lang}&q=${crypt}`;
      //localStorage.removeItem("q");
      //localStorage.setItem("q", crypt);
      window.open(url1, "_blank");
      changeStepper(1);
    } else {
      changeStepper(1);
      url1 = `${
        window.location.origin
      }/flow/${pagePath}/?pickup_location=${pickupLocation}&dropoff_location=${dropOffLocation}&pickup_date=${pickupDate}&pickup_time=${pickupTime}&dropoff_date=${returnDate}&dropoff_time=${returnTime}${
        vehilceTypeId != "" ? vehilceTypeId : ""
      }&clientid=${clientid}&q=${crypt}`; //
      //localStorage.removeItem("q");
      //localStorage.setItem("q", crypt);
      window.open(url1, "_blank");
    }
  } else {
    if (lang) {
      //localStorage.removeItem("q");
      //localStorage.setItem("q", crypt);
      if (clientdomain === null || clientdomain === "") {
        window.top.location = `${
          window.location.origin
        }/flow/${pagePath}/?pickup_location=${pickupLocation}&dropoff_location=${dropOffLocation}&pickup_date=${pickupDate}&pickup_time=${pickupTime}&dropoff_date=${returnDate}&dropoff_time=${returnTime}${
          vehilceTypeId != "" ? vehilceTypeId : ""
        }&clientid=${clientid}&lang=${lang}&q=${crypt}`; //&q=${crypt}
      } else {
        let iframeURL = "";
        iframeURL = `${
          window.location.origin
        }/flow/${pagePath}/?pickup_location=${pickupLocation}&dropoff_location=${dropOffLocation}&pickup_date=${pickupDate}&pickup_time=${pickupTime}&dropoff_date=${returnDate}&dropoff_time=${returnTime}${
          vehilceTypeId != "" ? vehilceTypeId : ""
        }&clientid=${clientid}&lang=${lang}&q=${crypt}`; //&q=${crypt}
        let newPath = `${clientdomain}/?path=${encodeURIComponent(iframeURL)}`;
        window.top.location = newPath;
      }
    } else {
      if (clientdomain === null || clientdomain === "") {
        window.top.location = `${
          window.location.origin
        }/flow/${pagePath}/?pickup_location=${pickupLocation}&dropoff_location=${dropOffLocation}&pickup_date=${pickupDate}&pickup_time=${pickupTime}&dropoff_date=${returnDate}&dropoff_time=${returnTime}${
          vehilceTypeId != "" ? vehilceTypeId : ""
        }&clientid=${clientid}&q=${crypt}`;
      } else {
        let iframeURL = "";
        iframeURL = `${
          window.location.origin
        }/flow/${pagePath}/?pickup_location=${pickupLocation}&dropoff_location=${dropOffLocation}&pickup_date=${pickupDate}&pickup_time=${pickupTime}&dropoff_date=${returnDate}&dropoff_time=${returnTime}${
          vehilceTypeId != "" ? vehilceTypeId : ""
        }&clientid=${clientid}&q=${crypt}`;
        let newPath = `${clientdomain}/?path=${encodeURIComponent(iframeURL)}`;
        window.top.location = newPath;
      }
    }
  }
};

const mapStoreHoursAsDisable = (arr, disableTill) => {
  return (date) => {
    if (!!disableTill)
      return arr.includes(date.getDay()) || isBefore(date, disableTill);

    return arr.includes(date.getDay());
  };
};

const avoidStoreWeeklyHolidaysWeekends = (storeHours, r) => {
  let i = 0;
  while (true) {
    const dateStoreHours = storeHours[r.getDay()];

    if (!dateStoreHours.isHoliday || i === 7) {
      return r;
    }
    i++;

    r = addDays(r, 1);
  }
};

const setMinimumBookingDays = (minimumBookingDays) => {
  const state = store.getState();
  state.UISettings.minimumBookingDays = minimumBookingDays;
};

const mapStoreHoursAsDefault = (
  checkInDate,
  checkOutDate,
  checkInTime,
  checkOutTime
) => {
  try {
    const state = store.getState();

    checkInTime = checkInTime || state.UISettings.defaultCheckinTime;

    if (
      state.UISettings.earliestPickup > 0 &&
      state.UISettings.hideOrDisableTimePicker === ""
    ) {
      let t = addMinutes(new Date(), state.UISettings.earliestPickup);

      checkOutTime = checkOutTime || format(t, "hh:mm aa");
    } else {
      checkOutTime = checkOutTime || state.UISettings.defaultCheckoutTime;
    }

    checkOutDate = checkOutDate || new Date();
    let checkoutMonth = checkOutDate.getMonth();
    let minimumBookingDaysConfig =
      state.UISettings.minimumBookingDaysFromConfig.length > 0
        ? state.UISettings.minimumBookingDaysFromConfig[checkoutMonth]
        : 0;
    if (eval(minimumBookingDaysConfig) > 0) {
      state.UISettings.minimumBookingDays = minimumBookingDaysConfig;
    }

    let minReturnDate = addDays(
      checkOutDate,
      state.UISettings.minimumBookingDays
        ? parseInt(state.UISettings.minimumBookingDays)
        : 1
    );
    if (state.storeHours.length > 0) {
      minReturnDate = avoidStoreWeeklyHolidaysWeekends(
        state.storeHours,
        minReturnDate
      );
    }

    checkInDate = checkInDate || minReturnDate;

    let checkInHour = parseInt(checkInTime.substring(0, 2));
    let checkInMinutes = parseInt(checkInTime.substring(3, 5));
    let checkOutHour = parseInt(checkOutTime.substring(0, 2));
    let checkOutMinutes = parseInt(checkOutTime.substring(3, 5));

    if (checkInTime.slice(-2) === "PM") {
      checkInHour += 12;
    }
    if (checkOutTime.slice(-2) === "PM") {
      checkOutHour += 12;
    }

    checkInTime = new Date();
    checkInTime.setHours(checkInHour);
    checkInTime.setMinutes(checkInMinutes);

    checkOutTime = new Date();
    checkOutTime.setHours(checkOutHour);
    checkOutTime.setMinutes(checkOutMinutes);

    // Now calculate the default Date
    if (state.UISettings.cutOffTime != null) {
      if (
        getTime(new Date()) >
        getTime(parse(state.UISettings.cutOffTime, "hh:mm aa", new Date()))
      ) {
        if (
          state.UISettings.addDaysForCheckout !== -1 &&
          state.UISettings.addDaysForCheckout !== null
        ) {
          checkOutDate.setDate(
            checkOutDate.getDate() +
              (state.UISettings.addDaysForCheckout
                ? parseInt(state.UISettings.addDaysForCheckout)
                : 0)
          );
          let minReturnDate = addDays(
            checkOutDate,
            state.UISettings.minimumBookingDays
              ? parseInt(state.UISettings.minimumBookingDays)
              : 1
          );
          if (state.storeHours.length > 0) {
            minReturnDate = avoidStoreWeeklyHolidaysWeekends(
              state.storeHours,
              minReturnDate
            );
          }
          checkInDate = minReturnDate;
        } else {
          checkOutDate.setDate(checkOutDate.getDate() + 2);
          checkInDate.setDate(checkInDate.getDate() + 3);
        }
      } else {
        checkOutDate.setDate(checkOutDate.getDate() + 1);
        checkInDate.setDate(checkInDate.getDate() + 2);
      }
    } else if (getTime(new Date()) > getTime(checkOutTime)) {
      if (
        state.UISettings.addDaysForCheckout !== -1 &&
        state.UISettings.addDaysForCheckout !== null
      ) {
        // checkOutDate = addDays(checkOutDate ,state.UISettings.addDaysForCheckout?parseInt(state.UISettings.addDaysForCheckout) : 0);
        checkOutDate.setDate(
          checkOutDate.getDate() +
            (state.UISettings.addDaysForCheckout
              ? parseInt(state.UISettings.addDaysForCheckout)
              : 0)
        );
        let minReturnDate = addDays(
          checkOutDate,
          state.UISettings.minimumBookingDays
            ? parseInt(state.UISettings.minimumBookingDays)
            : 1
        );
        if (state.storeHours.length > 0) {
          minReturnDate = avoidStoreWeeklyHolidaysWeekends(
            state.storeHours,
            minReturnDate
          );
        }
        checkInDate = minReturnDate;
      } else {
        checkOutDate = startOfTomorrow();
        let minReturnDate = addDays(
          checkOutDate,
          state.UISettings.minimumBookingDays
            ? parseInt(state.UISettings.minimumBookingDays)
            : 1
        );
        if (state.storeHours.length > 0) {
          minReturnDate = avoidStoreWeeklyHolidaysWeekends(
            state.storeHours,
            minReturnDate
          );
        }
        checkInDate = minReturnDate;
      }
    } else if (
      state.UISettings.addDaysForCheckout !== -1 &&
      state.UISettings.addDaysForCheckout !== null
    ) {
      // checkOutDate = addDays(checkOutDate ,state.UISettings.addDaysForCheckout?parseInt(state.UISettings.addDaysForCheckout) : 0);
      checkOutDate.setDate(
        checkOutDate.getDate() +
          (state.UISettings.addDaysForCheckout
            ? parseInt(state.UISettings.addDaysForCheckout)
            : 0)
      );
      let minReturnDate = addDays(
        checkOutDate,
        state.UISettings.minimumBookingDays
          ? parseInt(state.UISettings.minimumBookingDays)
          : 1
      );
      let returnDateFinal = avoidStoreWeeklyHolidaysWeekends(
        state.storeHours,
        minReturnDate
      );
      checkInDate = returnDateFinal;
    }

    if (state.UISettings.isNearestHour) {
      checkInTime = new Date();
      checkOutTime = new Date();

      checkInTime.setHours(new Date().getHours() + 1);
      checkInTime.setMinutes(0);

      checkOutTime.setHours(new Date().getHours() + 1);
      checkOutTime.setMinutes(0);
    }

    return {
      defaultCheckinDate: checkInDate,
      defaultCheckoutDate: checkOutDate,
      defaultCheckinTime: checkInTime,
      defaultCheckoutTime: checkOutTime,
    };
  } catch (err) {
    console.log(err);
    return;
  }
};

const mapToInputPicker = (obj = [], labelName, valueName, key) => {
  try {
    const newData = obj.map((item) => {
      return {
        label: item[labelName],
        value: item[valueName],
        key: item[key],
      };
    });

    return newData;
  } catch (err) {
    console.log(err);
    alert("Error Occured while maping the countrties.");
  }
};

export function groupObject(arrObj, key, group) {
  let misc = { others: [], othersWithOptions: [] };

  arrObj.forEach((obj) => {
    if (group.includes(obj[key])) {
      if (misc[obj[key]]) {
        misc[obj.MiscChargeType].push(obj);
      } else {
        misc[obj[key]] = [obj];
      }
    } else {
      if (!obj.Options || obj.Options.length === 0) {
        misc.others.push(obj);
      } else {
        misc.othersWithOptions.push(obj);
      }
    }
  });

  // To take shift others property at the end of the misc object
  const others = [...misc.others];

  delete misc.others;

  misc.others = others;

  return misc;
}

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export {
  jumpToSteps,
  mapStoreHoursAsDisable,
  formatFormData,
  mapStoreHoursAsDefault,
  mapToInputPicker,
  avoidStoreWeeklyHolidaysWeekends,
  setMinimumBookingDays,
};
