import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import languages from './locales';


const fallbackLng = ['en'];
const availableLanguages = ['en', 'ar', 'fr'];

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.

    resources: languages,


  });

export default i18n;