import ActionType from "./ActionsType";

const setSummery = (summery) => {
  return (dispatch) => {
    try {
      dispatch({ type: ActionType.SET_SUMMERY, data: { summery } });
    } catch (err) {
      console.log(err);
      alert("Error occured while loading the summery.");
    }
  };
};

const setMultiVehicleSummary = (summary) => {
  return (dispatch) => {
    try {
      dispatch({
        type: ActionType.SET_MULTI_VEHICLE_SUMMERY,
        data: { summary },
      });
    } catch (err) {
      console.log(err);
      alert("Error occured while loading the summary.");
    }
  };
};

export { setSummery, setMultiVehicleSummary };
